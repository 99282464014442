//ID serwera minus 1.
import { override6 } from './_theme6';
import { override } from './_default';
export default function getOverride(theme_id) {
  console.log('theme_id: ', theme_id);
  switch (theme_id) {
    case 6:
      return override6;
    default:
      return override;
  }
}
