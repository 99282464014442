module.exports = `
  type Portal {
    portal_id: Int
    portal_group_id: Int
    name: String
    domain: String
    domain_url: String
    active: Boolean
    logo_url: String
    theme_id: Int
    theme: Theme
    theme_mode: Int
    theme_color_id: Int
    theme_color: [ThemeColor]
    headline_photomode_id: Int
    watermark_url: String
    watermark_position_id: Int
    watermark_position: [WatermarkPosition]
    done: Boolean
    menu: MenuData
    footerMenu: MenuData
    copyright: Copyright
    facebook_api_key: String
    banner_options: BannerOptions
    mourning: Boolean
  }

  type BannerOptions {
    rotation_banner_first: Int
    rotation_banner: Int
    show_banner_thumbnails: Boolean
  }


  enum PortalFileType {
    logo
    watermark
  }
`;
