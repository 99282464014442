module.exports = `
  type Page {
    page_id: Int
    portal_id: Int
    page_parent_id: Int
    gallery_id: Int
    name: String
    url: String
    url_canonic: String
    meta_title: String
    meta_description: String
    content: String
    url_outside: String
    url_outside_blank: Boolean
    url_outside_nofollow: Boolean
    show_mega_menu: Boolean
    image_main_url: String
    image_header_url: String
    no: Int
    unlocked: Boolean
    active: Boolean
    done: Boolean
    date_add: String
    date_mod: String 
  } 
`;
