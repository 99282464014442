import { darken, rgba, lighten } from '../Mixins.style';
import { Colors } from '../Variables.style';
import {
  faAngleDoubleRight,
  faArrowRight,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import Clouds from '../Images/clouds.png';
import FooterTheme4 from '../Images/footerTheme4.svg';
const theme = {
  global: {
    background: ` url(${FooterTheme4}) no-repeat bottom -20px center scroll, url(${Clouds}) no-repeat top center scroll, ${Colors.card} `,
    backgroundOnMobile: Colors.card,
    fontFamily: 'Lato, sans-serif'
  },
  articlePage: {
    background: 'transparent'
  },
  container: {
    background: Colors.card,
    paddingDefault: '67px 42px',
    paddingLg: '47px 22px',
    paddingMd: ' 15px',
    borderRadius: '8px'
  },
  fontface: {
    primaryFont: 'Lato',
    primaryFontName: 'Lato',
    secondaryFont: 'Segoe UI',
    secondaryFontName: 'Segoe UI'
  },
  title: {
    wrapper: {
      alignItems: 'center',
      margin: {
        default: '0 0 50px 0',
        md: '0 0 20px 0'
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      isBackground: true,
      background: 'transparent',
      shape: undefined,
      shapePadding: {
        default: 0,
        lg: 0
      }
    },
    text: {
      padding: {
        default: '0px 50px 0px 8px',
        md: '0 25px 0px 8px'
      },
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      margin: {
        default: '2px 2px 2px auto',
        md: '2px 2px 2px auto'
      },
      padding: {
        default: '5px',
        md: '5px'
      },
      borderRadius: {
        default: 0,
        md: 0,
        sm: 0
      },
      background: 'transparent',
      color: {
        default: Colors.blueberry,
        lg: Colors.blueberry
      },
      colorOnHover: {
        default: darken(Colors.blueberry, 50),
        lg: darken(Colors.blueberry, 50)
      },
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 400,
      triangleShape: undefined
    },
    arrow: {
      margin: {
        default: '0 0 0 5px',
        md: '0 0 0 5px'
      },
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.blueberry,
      colorOnHover: darken(Colors.blueberry, 50)
    },
    hrColor: Colors.wolfGrey
  },
  button: {
    padding: {
      default: '15px 25px',
      md: '5px 15px'
    },
    background: Colors.blueberry,
    backgroundOnHover: darken(Colors.blueberry, 50),
    color: Colors.white,
    colorOnHover: Colors.white,
    fontFamily: 'Lato, sans-serif',
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1,
    fontWeight: 700
  },
  buttonIcon: {
    position: 'right-center',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: {
      default: '0 5px 0 5px',
      md: '0 5px 0 5px'
    },
    borderRadius: {
      default: 0,
      md: 0
    },
    background: 'transparent',
    color: 'inherit'
  },
  loadingScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.blueberry
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  errorScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.blueberry
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  loadingData: {
    wrapper: {
      primaryBackground: 'transparent',
      secondaryBackground: 'transparent',
      color: Colors.wolfGrey
    },
    text: {
      fontSize: {
        default: 3.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 900
    }
  },
  toolbar: {
    wrapper: {
      color: Colors.white
    },
    item: {
      left: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: 'transparent'
      },
      right: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: 'transparent'
      }
    }
  },
  toolbarSocial: {
    item: {
      color: Colors.blueberry,
      backgroundOnHover: Colors.white,
      padding: '4px 2px',
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  toolbarSettings: {
    item: {
      color: Colors.blueberry,
      backgroundOnHover: Colors.white,
      padding: '4px',
      backgroundOnActive: Colors.white,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  header: {
    wrapper: {
      background: 'transparent',
      boxShadow: `0`,
      padding: {
        default: '15px 0 0',
        lg: '10px 0',
        md: '5px 0',
        sm: '2px 0'
      },
      zIndex: 2
    },
    box: {
      gridGap: 0
    },
    toggleButton: {
      color: Colors.newYork,
      colorOnHover: Colors.white,
      backgroundOnHover: Colors.newYork,
      colorOnToggle: Colors.white,
      backgroundOnToggle: Colors.newYork
    },
    logo: {
      image: {
        width: 1395,
        height: 690,
        widthTablet: 969,
        heightTablet: 480,
        widthMobile: 767,
        heightMobile: 480,
        crop: 0
      }
    }
  },
  blackHole: {
    wrapper: {
      color: Colors.blueberry,
      backgroundColor: Colors.cardOpacity,
      padding: '10px'
    },
    title: {
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.2,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  banner: {
    box: {
      boxShadow: `0 0 36px 0 ${rgba(Colors.black, 0.16)}`
    },
    item: {
      height: {
        default: '690px',
        lg: '480px'
      }
    },
    image: {
      width: 1395,
      height: 690,
      widthTablet: 969,
      heightTablet: 480,
      widthMobile: 767,
      heightMobile: 480,
      background: Colors.bigStone
    },
    contentInner: {
      gridRows: {
        default: '1fr 90px',
        lg: '1fr'
      }
    },
    details: {
      justifyContent: 'flex-end',
      padding: {
        default: '25px 0 55px',
        md: '15px 0'
      }
    },
    detailsInner: {
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent'
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '0',
        md: '0'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      color: Colors.white,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    button: {
      hideBackground: true,
      faIcon: faAngleDoubleRight,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.white,
      colorOnHover: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    buttonIcon: undefined,
    navigation: {
      top: 0,
      bottom: '90px'
    },
    travel: {
      margin: {
        default: '-90px 0 0',
        lg: 0
      }
    }
  },
  bannerTravel: {
    holder: {
      shape: undefined
    },
    wrapper: {
      margin: {
        default: 0,
        lg: 0
      },
      borderRadius: { default: '4px', lg: 0 },
      background: Colors.white,
      boxShadow: {
        default: `0 0 3px 0 ${rgba(Colors.black, 0.16)}`,
        lg: `none`
      }
    },
    box: {
      padding: {
        default: '10px 0',
        lg: 0
      }
    },
    item: {
      padding: {
        default: '10px 20px',
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    title: {
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  bannerNavigation: {
    wrapper: {
      top: '0',
      transform: 'none',
      height: '100%'
    },
    panel: {
      background: rgba(Colors.black, 0.6)
    },
    panelInner: {
      margin: 0,
      padding: '0 15px',
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    navigationButton: {
      position: 'relative',
      width: '36px',
      height: '36px',
      isBackground: false,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.white,
      colorOnHover: Colors.white,
      prev: {
        top: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: 0
      },
      next: {
        bottom: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: 0
      }
    },
    box: {
      gridGap: '20px'
    },
    item: {
      minHeight: '150px',
      padding: '0',
      background: 'transparent',
      backgroundOnSelected: Colors.tango,
      border: '5px solid transparent',
      borderOnSelected: `5px solid`,
      borderOnSelectedColor: Colors.tango,
      borderAfterOnSelected: `14px solid`,
      borderAfterOnSelectedColor: Colors.tango
    },
    itemInner: {
      gridColumns: '200px 1fr',
      background: Colors.white
    },
    imagebox: {
      width: '200px',
      minHeight: '100%',
      height: '100%',
      margin: 0,
      triangleShape: undefined
    },
    image: {
      width: 200,
      height: 150,

      borderRadius: '4px 0 0 4px'
    },
    featured: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'initial',
      isText: true,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '4px 5px',
      borderRadius: 0,
      background: Colors.tango,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      width: 'auto',
      height: 'auto',
      margin: '0 5px 0 0',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: 1
    },
    details: {
      padding: '20px',
      triangleShape: undefined
    },
    title: {
      margin: '0 0 15px 0',
      padding: 0,
      color: Colors.bigStone,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.8,
      lineHeight: 1.2,
      fontWeight: 700
    },
    link: {
      isText: true,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700
    },
    arrow: {
      position: 'right-center',
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: 0,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  headline: {
    margin: {
      default: '0 0 32px 0',
      md: '0 0 16px 0'
    },
    category: {
      margin: {
        default: '0 0 12px 0',
        md: '0 0 8px 0'
      },
      color: Colors.newYork,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    title: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 4.0,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    date: {
      margin: {
        default: '0 0 35px 0'
      },
      color: Colors.orange,
      fontSize: {
        default: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 500
    },
    subtitle: {
      margin: {
        default: '0 0 16px 0',
        md: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    imagebox: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    image: {
      width: 669,
      height: 378,
      widthTablet: 922,
      heightTablet: 521,
      widthMobile: 720,
      heightMobile: 407,

      borderRadius: {
        default: 0,
        md: 0
      }
    }
  },
  articleGallery: {
    wrapper: {
      marginDefault: ' 0 0 0 0',
      marginMd: ' 0 0 0 0'
    },
    box: {
      gridTemplateColumns: 4,
      gridTemplateColumnsMD: 2,
      gridGap: 24
    },
    title: {
      hrBackground: Colors.wolfGrey,
      margin: {
        default: '0 0 50px 0',
        md: '0 20px 0 0'
      },
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    caption: {
      fontFamily: 'Segoe UI, sans-serif'
    },
    image: {
      width: 174,
      height: 174,
      widthTablet: 174,
      heightTablet: 174,
      widthMobile: 174,
      heightMobile: 174
    }
  },
  widgets: {
    wrapper: {
      gridGap: {
        default: '24px',
        md: '24px'
      }
    }
  },
  widgetDisabledFacilities: {
    item: {
      color: Colors.blueberry,
      colorOnHover: darken(Colors.blueberry, 50),
      colorOnActive: darken(Colors.blueberry, 50),
      background: 'transparent',
      backgroundOnHover: Colors.white,
      backgroundOnActive: Colors.white,
      padding: '4px',
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    wrapper: {
      background: 'transparent'
    }
  },
  widgetTitle: {
    title: {
      margin: {
        default: '0 0 0px 0',
        md: '0 0 0px 0'
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      isBackground: false,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: true,
      background: 'transparent'
    }
  },
  widgetPartners: {
    wrapper: {
      padding: {
        default: '30px 24px 14px'
      },
      borderRadius: '4px',
      backgroundColor: Colors.white,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
    },
    title: {
      margin: {
        default: '0 auto 38px auto',
        md: '0 auto 19px auto'
      },
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    imageBox: {
      padding: {
        default: '10px 17px'
      },
      border: `1px solid ${Colors.casper}`,
      borderRadius: '4px',
      maxWidth: '224px'
    },
    dot: {
      margin: {
        default: '16px 2px 0 2px',
        md: '16px 2px 0 2px'
      },
      bgColor: Colors.casper,
      bgColorOnActive: Colors.blueberry,
      size: '10px'
    }
  },
  widgetSchoolMenu: {
    backgroundColor: Colors.casper,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.ziggurat,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      backgroundColor: Colors.ziggurat,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    mealsBox: {
      padding: {
        default: '0 20px 18px'
      }
    },
    meal: {
      margin: {
        default: '0 0 24px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      border: `1px dashed ${Colors.white}`
    },
    innerTitle: {
      margin: {
        default: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    decoration: {
      color: Colors.orange,
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.8
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    footer: {
      backgroundColor: Colors.blueberry,
      padding: {
        default: '20px 64px 20px 20px'
      }
    },
    footerText: {
      margin: { default: '0' },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetLessonPlan: {
    backgroundColor: Colors.lightGreen,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.jetStream,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    selectContainer: {
      padding: {
        default: '0 24px 40px'
      },
      backgroundColor: Colors.jetStream
    },
    selectText: {
      margin: {
        default: '0 0 4px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    select: {
      padding: {
        default: '9px 17px 9px 12px'
      },
      border: `1px solid ${Colors.acapulco}`,
      borderRadius: '4px',
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '5px 0 24px 0'
      },
      backgroundColor: Colors.jetStream,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    table: {
      padding: {
        default: '0 20px 34px'
      }
    },
    content: {
      color: Colors.blueberry,
      secondColor: Colors.white,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 700,
      lineHeight: 1.2,
      row: {
        padding: {
          default: '16px 4px 16px 4px'
        },
        border: `1px dashed ${Colors.white}`
      }
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetSpacer: {
    spacer: {
      background: Colors.blizzardBlue
    }
  },
  widgetList: {
    title: {
      display: 'flex',
      margin: {
        default: '0 0 28px 0',
        md: '0 0 15px 0'
      },
      padding: {
        default: '0px 0px 18px 0px',
        md: '0px 0px 10px 0px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      isBackground: false,
      color: Colors.blueberry,
      borderColor: `1px solid ${Colors.wolfGrey}`,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    box: {
      gridGap: {
        default: '16px',
        md: '16px'
      }
    }
  },
  widgetItem: {
    item: {
      minHeight: {
        default: 'initial',
        md: 'initial'
      },
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: ' 0',
        md: ' 0'
      },
      gridGap: {
        default: '24px',
        md: '10px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      alignItems: 'start',
      gridTemplateColumns: '75px 1fr',
      background: 'transparent',
      border: `0`,
      borderFirstTop: `0`,
      borderLastBottom: `0`,
      boxShadow: 'none'
    },
    readMore: {
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      fontSize: 1.4,
      fontWeight: 700
    },

    imagebox: {
      margin: {
        default: 0,
        md: 0
      },
      width: '75px',
      height: '75px'
    },
    image: {
      width: 75,
      height: 75,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      margin: {
        default: '0',
        md: '0'
      },
      color: Colors.bigStone,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  widgetContactForm: {
    title: {
      margin: {
        default: '0 0 5px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: Colors.white,
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: true,
      background: Colors.blizzardBlue
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      },
      padding: {
        default: '20px 0',
        md: '10px 0'
      }
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    textarea: {
      padding: {
        default: '10px',
        md: '5px'
      },
      background: Colors.white,
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkboxLabel: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkbox: {
      background: Colors.white,
      color: Colors.blueberry
    },
    button: {
      padding: {
        default: '0 10px',
        md: '0 5px'
      },
      background: Colors.blueberry,
      backgroundOnHover: darken(Colors.blueberry, 50),
      color: Colors.white,
      colorOnHover: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetImage: {
    image: {}
  },
  widgetAdvertisement: {
    image: {}
  },
  widgetBanner: {
    image: {},
    title: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    box: {
      padding: 14,
      paddingMd: 5,
      margin: '0 -15px',
      marginCont: '0 -15px',
      backgroundColor: Colors.white,
      overflow: 'hidden'
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.4,
      offset: 'calc(-42px - 14px)',
      size: 84
    }
  },
  widgetIcon: {
    item: {
      color: Colors.blueberry
    },
    text: {
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetLuckyNumber: {
    item: {
      maxWidth: '100%'
    },
    imageBox: {
      top: 'auto',
      bottom: '-17px',
      left: '50%',
      right: 0,
      width: '84%',
      transform: 'translateX(-50%)'
    },
    content: {
      padding: {
        default: '0',
        md: '0'
      },
      top: '10px',
      left: '-10px',
      transform: 'none',
      gridColumns: '1fr',
      width: '100%'
    },
    title: {
      margin: {
        default: '0 0 20px 0'
      },
      color: Colors.white,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.33,
      fontWeight: 400
    },
    text: {
      color: Colors.orange,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 5.9,
        lg: 5.9,
        md: 4.8,
        sm: 4.8
      },
      lineHeight: 1.33,
      fontWeight: 400,
      fontStyle: 'normal'
    }
  },
  articlesList: {
    wrapper: {
      margin: {
        default: '0 0 45px 0',
        lg: '0 0 45px 0',
        md: '0 0 20px 0',
        sm: '0 0 20px 0'
      }
    },
    box: {
      gridGap: {
        default: '24px',
        lg: '24px',
        md: '16px',
        sm: '16px'
      },
      gridColumns: {
        default: 'repeat(2, 1fr)',
        lg: 'repeat(2, 1fr)',
        md: '1fr',
        sm: '1fr'
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      right: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      }
    },
    item: {
      height: '100%',
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      gridGap: '0',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '16px',
        md: '10px'
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      border: {
        top: 'none',
        bottom: 'none',
        left: 'none',
        right: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      background: Colors.white,
      borderRadius: {
        default: '4px',
        md: '4px'
      },
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
    },
    imagebox: {
      maxWidth: '100%',
      height: 'auto',
      margin: {
        default: '0 0 16px 0',
        lg: '0 0 16px 0',
        md: '0 0 8px 0',
        sm: '0 0 8px 0'
      },
      triangleShape: undefined
    },
    image: {
      width: 340,
      height: 210,
      widthTablet: 456,
      heightTablet: 342,
      widthMobile: 720,
      heightMobile: 540,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    featured: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'initial',
      isText: true,
      minWidth: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      minHeight: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      padding: '4px 5px',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: Colors.tango,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      width: 'auto',
      height: 'auto',
      margin: '0 5px 0 0',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: {
        default: 1,
        lg: 1,
        md: 1,
        sm: 1
      }
    },
    details: {
      height: 'auto',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      triangleShape: undefined
    },
    title: {
      margin: {
        default: '0 0 16px 0',
        md: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.33,
      fontWeight: 900
    },
    text: {
      margin: {
        default: '0 0 16px 0',
        md: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.3,
      fontWeight: 400
    },
    link: {
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.5,
      fontWeight: 700
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      size: 1.2,
      borderRadius: {
        default: 0,
        md: 0
      },
      faIcon: faArrowRight,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      verticalAlign: 'baseline'
    }
  },
  galleriesGrid: {
    box: {
      columnGap: '25px',
      rowGap: '17px'
    },
    imagebox: {
      margin: {
        default: '0 0 10px 0'
      },
      after: {
        padding: '8px 23px 8px 23px',
        backgroundColor: Colors.newYork,
        backgroundColorOnHover: darken(Colors.newYork, 25),
        color: Colors.white,
        content: '',
        fontFamily: 'Lato, sans-serif',
        fontSize: 1.4,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    text: {
      color: Colors.blueberry,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      },
      fontWeight: 700,
      lineHeight: 1.2
    }
  },
  articlesGrid: {
    wrapper: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: 'none',
      gridTemplateColumns: {
        default: 'repeat(2, 1fr)',
        lg: 'repeat(2, 1fr)',
        md: '1fr',
        sm: '1fr'
      },
      padding: {
        default: '0px 0 0px',
        md: '0px 0 0px'
      },
      gridGap: {
        default: '24px',
        lg: '24px',
        md: '16px',
        sm: '16px'
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        gridArea: {
          default: '1 / 1 / 2 / 2',
          lg: '1 / 1 / 2 / 2',
          md: '1 / 1 / 2 / 2',
          sm: '1 / 1 / 2 / 2'
        },
        shape: undefined,
        left: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    item: {
      height: '100%',
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '16px',
        md: '10px'
      },
      border: {
        top: 'none',
        bottom: 'none',
        left: 'none',
        right: 'none'
      },
      background: Colors.white,
      borderRadius: {
        default: '4px',
        md: '4px'
      },
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      main: {
        gridColumns: {
          default: '1fr',
          lg: '1fr',
          md: '1fr',
          sm: '1fr'
        },
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '16px',
          md: '10px'
        },
        background: Colors.white,
        borderRadius: {
          default: '4px',
          md: '4px'
        },
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
      }
    },
    imagebox: {
      alignSelf: 'start',
      maxWidth: '100%',
      margin: {
        default: '0 0 16px 0',
        lg: '0 0 16px 0',
        md: '0 0 8px 0',
        sm: '0 0 8px 0'
      },

      main: {
        maxWidth: '100%',
        margin: {
          default: '0 0 16px 0',
          lg: '0 0 16px 0',
          md: '0 0 8px 0',
          sm: '0 0 8px 0'
        }
      }
    },
    image: {
      width: 340,
      height: 210,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 263,

      borderRadius: {
        default: 0,
        md: 0
      },
      main: {
        width: 340,
        height: 210,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 720,
        heightMobile: 540,

        borderRadius: {
          default: 0,
          md: 0
        }
      }
    },
    featured: {
      position: undefined,
      isText: true,
      minWidth: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      minHeight: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: Colors.newYork,
      color: Colors.white,
      fontSize: {
        default: 1.2,
        lg: 1.2,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      padding: '5px',
      main: {
        position: undefined,
        isText: true,
        minWidth: {
          default: 'auto',
          lg: 'auto',
          md: 'auto',
          sm: 'auto'
        },
        minHeight: {
          default: 'auto',
          lg: 'auto',
          md: 'auto',
          sm: 'auto'
        },
        borderRadius: {
          default: 8,
          md: 8
        },
        background: Colors.tango,
        color: Colors.white,
        fontSize: {
          default: 1.2,
          lg: 1.2,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1,
        lg: 1,
        md: 1,
        sm: 1
      },
      margin: '0 5px 0 0',
      color: Colors.white,
      main: {
        fontSize: {
          default: 1,
          lg: 1,
          md: 1,
          sm: 1
        },
        margin: '0 5px 0 0',
        color: Colors.white
      },
      faIcon: faArrowRight
    },
    details: {
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    title: {
      margin: {
        default: '0 0 16px 0',
        md: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.6,
        sm: 1.6
      },
      main: {
        fontSize: {
          default: 2.1,
          lg: 2.1,
          md: 1.6,
          sm: 1.6
        }
      },
      lineHeight: 1.33,
      fontWeight: 900
    },
    text: {
      show: true,
      margin: {
        default: '0 0 16px 0',
        md: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.3,
      fontWeight: 400
    },
    link: {
      isText: true,
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.5,
      fontWeight: 700,
      faIcon: faArrowRight,
      main: {
        isText: true,
        fontSize: {
          default: 1.4,
          lg: 1.4,
          md: 1.2,
          sm: 1.2
        },
        faIcon: faArrowRight
      }
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      size: 1.2,
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      main: {
        display: 'inline-flex'
      },
      verticalAlign: 'baseline'
    }
  },
  pagination: {
    current: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '20px',
        md: '20px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      color: Colors.newYork,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    link: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '16px',
        md: '16px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.astronautBlue,
      colorOnHover: Colors.newYork,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    linkArrow: {
      borderRadius: { default: '50%', lg: '50%' },
      margin: '2px 12px 2px 12px',
      minWidth: {
        default: '39px',
        md: '39px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '39px',
        md: '39px'
      },
      background: Colors.white,
      backgroundOnHover: Colors.white,
      boxShadow: ' 0px 3px 6px rgba(0,0,0, 0.16)',
      color: Colors.newYork,
      colorOnHover: darken(Colors.newYork, 50),
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: 'inherit',
      colorOnHover: 'inherit',
      faIconRight: faAngleRight,
      faIconLeft: faAngleLeft
    }
  },
  navMenu: {
    item: {
      padding: {
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    title: {
      color: Colors.curiousBlue,
      colorOnHover: darken(Colors.curiousBlue, 50),
      fontSize: {
        default: 1.4,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  menuList: {
    wrapper: {
      position: undefined,
      shape: undefined,
      padding: { default: 0, lg: 0 },
      borderRadius: { default: 0, lg: 0 },
      background: { default: 'transparent', lg: 'transparent' },
      marginBottom: 0
    },
    content: {
      position: undefined,
      margin: { default: '24px 0 0px 20px' }
    },
    link: {
      padding: {
        default: '26px 40px',
        lg: '20px',
        md: '10px 0'
      },
      minHeight: {
        default: '78px',
        defaultOnHover: '108px',
        lgOnHover: '1px',
        lg: 'auto'
      },
      border: Colors.blueberry,
      color: Colors.newYork,
      colorOnHover: Colors.blueberry,
      colorOnActive: Colors.blueberry,
      backgroundOnHover: undefined,
      backgroundOnActive: undefined,
      margin: '30px 0px 0px -20px',
      marginOnHover: '0px 0px 0px -20px',
      width: 'calc(100% + 20px)',
      backgroundMobileOnHover: 'transparent',
      backgroundMobileOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      boxShadowMobileOnHover: `none`,
      boxShadowMobileOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    subLink: {
      padding: {
        default: ' 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blueberry,
      color: Colors.newYork,
      colorOnHover: Colors.blueberry,
      colorOnActive: Colors.blueberry,
      background: Colors.card,
      backgroundOnMobile: 'transparent',
      backgroundOnHover: undefined,
      backgroundOnActive: undefined,
      backgroundMobileOnHover: 'transparent',
      backgroundMobileOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      boxShadowMobileOnHover: `none`,
      boxShadowMobileOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    option: {
      padding: {
        default: '26px 40px',
        lg: '20px',
        md: '10px 0'
      },
      minHeight: {
        default: '78px',
        defaultOnHover: '108px',
        lgOnHover: '1px',
        lg: 'auto'
      },
      borderRadius: { default: 0, lg: 0 },
      border: Colors.blueberry,
      color: Colors.newYork,
      colorOnHover: Colors.blueberry,
      colorOnActive: Colors.blueberry,
      backgroundOnHover: undefined,
      backgroundOnActive: undefined,
      margin: '30px 0px 0px -20px',
      marginOnHover: '0px 0px 0px -20px',
      width: 'calc(100% + 20px)',
      backgroundOnActive: 'transparent',
      backgroundMobileOnHover: 'transparent',
      backgroundMobileOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      boxShadowMobileOnHover: `none`,
      boxShadowMobileOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    subOption: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blueberry,
      color: Colors.bigStone,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      backgroundOnHover: Colors.blizzardBlue,
      backgroundOnActive: Colors.blizzardBlue,
      backgroundMobileOnHover: 'transparent',
      backgroundMobileOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowMobileOnHover: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    itemContent: {
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  megamenu: {
    wrapper: {
      padding: {
        default: 0,
        lg: '20px 0 0',
        md: '10px 0'
      },
      background: { default: Colors.card, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    main: {
      padding: {
        default: '20px 20px 0',
        lg: 0,
        md: 0
      }
    },
    aside: {
      boxShadow: {
        default: `inset 12px 0 12px -6px ${rgba(Colors.black, 0.16)}`,
        lg: 'none'
      },
      maxWidth: '346px',
      padding: {
        default: '20px 40px 0',
        lg: 0,
        md: 0
      },
      background: {
        default: rgba(Colors.doveGray, 0.15),
        lg: 'transparent'
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 1.8,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    item: {
      padding: {
        default: '20px 0',
        md: '10px 0'
      },
      border: Colors.blueberry
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      color: Colors.newYork,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 900
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  megamenuList: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    item: {
      padding: {
        default: '10px 0',
        lg: '10px 0',
        md: '5px 0'
      }
    },
    text: {
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 900
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  cookies: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 -3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    text: {
      margin: {
        default: '15px 0',
        md: '5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  CMS: {
    color: Colors.blueberry,
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1.5,
    fontWeight: 400,
    cms: {
      h1: {
        fontSize: {
          default: 2.1,
          lg: 2.1,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h2: {
        fontSize: {
          default: 2.1,
          lg: 2.1,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h3: {
        fontSize: {
          default: 2.0,
          lg: 2.0,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h4: {
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.8,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h5: {
        fontSize: {
          default: 1.8,
          lg: 1.6,
          md: 1.6,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h6: {
        fontSize: {
          default: 1.6,
          lg: 1.6,
          md: 1.4,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      a: {
        color: Colors.newYork,
        colorOnHover: darken(Colors.newYork, 50)
      },
      mark: {
        background: Colors.candlelight
      },
      ul: {
        padding: {
          default: '0 0 0 30px',
          md: '0 0 0 15px'
        },
        markerColor: Colors.newYork,
        markerSize: 2
      },
      ol: {
        padding: {
          default: '0 0 0 30px',
          md: '0 0 0 15px'
        }
      },
      table: {
        border: Colors.bigStone
      },
      tr: {
        background: Colors.white,
        backgroundAlt: Colors.mercury
      },
      th: {
        border: Colors.bigStone
      },
      td: {
        border: Colors.bigStone
      },
      blockquote: {
        fontFamily: 'Segoe UI, sans-serif',
        fontSize: {
          default: 2.1,
          lg: 2.1,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.5,
        fontWeight: 400,
        marginLeft: '133px',
        padding: '0',
        quote: {
          margin: {
            default: '-7.5rem 65px 0 0',
            md: '-5.5rem 55px 0 0',
            sm: '-4.5rem 45px 0 0'
          },
          color: Colors.orange,
          background: 'transparent',
          fontFamily: 'Segoe UI, sans-serif',
          fontSize: {
            default: 18.0,
            lg: 18.0,
            md: 14.0,
            sm: 10.0
          },
          lineHeight: 1,
          fontWeight: 400
        }
      }
    }
  },
  modal: {
    holder: {
      background: rgba(Colors.black, 0.6),
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    panel: {
      background: Colors.white,
      shadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    close: {
      color: Colors.bigStone,
      colorOnHover: Colors.tango
    }
  },
  popup: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    }
  },
  footerMenu: {
    wrapper: {
      background: 'transparent',
      backgroundOnMobile: Colors.lightGreen,
      padding: {
        default: '2px 0 15px',
        md: '2px 0'
      }
    },
    item: {
      color: Colors.white,
      colorOnHover: darken(Colors.white, 20)
    },
    text: {
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.5,
      fontWeight: 400
    }
  },
  footerDetails: {
    wrapper: {
      background: Colors.blueberry,
      padding: {
        default: '38px 0 29px',
        md: '21px 0 15px'
      }
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    column: {
      border: `1px solid ${Colors.pickled}`,
      padding: '8px 24px',
      textAlignLg: 'left'
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  },
  footerCopyright: {
    wrapper: {
      background: Colors.bluewood,
      padding: {
        default: '12px 0',
        md: '12px 0'
      }
    },
    text: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1,
      fontWeight: 400,
      textAlign: {
        default: 'left',
        sm: 'center'
      },
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  },
  widgetItemsGrid: {
    wrapper: {
      margin: {
        default: '0 0 0px 0',
        md: '0 0 0px 0'
      }
    },
    box: {
      gridGap: {
        default: '24px',
        md: '24px'
      },
      gridTemplateColumns: {
        default: '1fr 1fr ',
        md: '1fr'
      }
    },
    item: {
      minHeight: {
        default: 'initial',
        sm: 'initial'
      },
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: '8px',
        md: ' 8px'
      },
      gridGap: {
        default: '24px',
        md: '10px'
      },
      borderRadius: {
        default: '4px',
        md: '4px'
      },
      gridTemplateColumns: '90px 1fr ',
      alignItems: 'center',
      background: Colors.white,
      border: `1px solid ${Colors.galleryTwo}`,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      boxShadowOnHover: '0px 0px 16px rgba(0, 0, 0, 0.3)'
    },

    imagebox: {
      margin: {
        default: 0,
        md: 0
      },
      width: '90px',
      height: '90px'
    },
    image: {
      width: 90,
      height: 90,
      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 900,
      textAlign: {
        default: 'left',
        sm: 'left'
      }
    }
  },
  widgetLinksUnderBanner: {
    wrapper: {
      margin: {
        default: '0 0 0px 0',
        md: '0 0 0px 0'
      }
    },
    inner: {
      backgroundOnBanner: 'linear-gradient(transparent, #000000)',
      background: 'transparent'
    },
    box: {
      borderTop: `1px solid ${Colors.white}`
    },
    item: {
      color: Colors.blueberry,
      colorOnBanner: Colors.white,
      colorOnHover: lighten(Colors.blueberry, 50),
      colorOnBannerOnHover: darken(Colors.white, 50),
      minHeight: {
        default: 'initial',
        sm: 'initial'
      },
      maxWidth: '260px',
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: '24px 0px',
        md: ' 16px '
      },
      gridGap: {
        default: '16px',
        md: '16px'
      },
      borderRadius: {
        default: '4px',
        md: '4px'
      },
      gridTemplateColumns: '60px 1fr ',
      alignItems: 'center',
      background: 'transparent',
      border: `none`,
      boxShadow: 'none',
      boxShadowOnHover: 'none'
    },

    imagebox: {
      margin: {
        default: 0,
        md: 0
      },
      width: '60px',
      height: '60px'
    },
    image: {
      width: 60,
      height: 60,
      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      color: 'inherit',
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400,
      textAlign: {
        default: 'left',
        sm: 'left'
      }
    }
  }
};

export default theme;
