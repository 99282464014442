export const override = {
  // Belka górna, strona lewa - tło
  0: ['toolbar.item.left.background'],

  // Belka górna, strona lewa - przycisk - tło
  1: ['toolbarSocial.item.background'],

  // Belka górna, strona lewa - przycisk - tło - hover
  2: ['toolbarSocial.item.backgroundOnHover'],

  // Belka górna, strona lewa - przycisk - tekst
  3: ['toolbarSocial.item.color'],

  // Belka górna, strona lewa - przycisk - tekst hover
  4: ['toolbarSocial.item.colorOnHover'],

  // Belka górna, strona prawa - tło
  5: ['toolbar.item.right.background'],

  // Belka górna, strona prawa - przycisk - tło
  6: ['toolbarSettings.item.background'],

  // Belka górna, strona prawa - przycisk - tło - hover
  7: ['toolbarSettings.item.backgroundOnHover'],
  // Belka górna, strona prawa - przycisk - tekst
  8: ['toolbarSettings.item.color'],

  // Belka górna, strona prawa - przycisk - tekst hover
  9: ['toolbarSettings.item.colorOnHover'],

  // Belka dolna - tło
  10: ['footerCopyright.wrapper.background'],

  // Belka dolna - tekst
  11: ['footerCopyright.text.color'],

  // Belka dolna - link
  12: ['footerCopyright.text.link.color'],

  // Belka dolna - link - hover
  13: ['footerCopyright.text.link.colorOnHover'],

  // Nagłówek, logo, menu - tło
  14: [
    'header.wrapper.background',
    'menuList.wrapper.background.default',
    'menuList.wrapper.background.lg',
    'menuList.content.background.default',
    'menuList.itemContent.background.default',
    'menuList.itemContent.background.lg',
    'megamenu.wrapper.background.default',
    'megamenu.wrapper.background.lg'
  ],

  // Menu główne - link - tekst
  15: [
    'menuList.link.color',
    'menuList.subLink.color',
    'menuList.option.color',
    'menuList.subOption.color',
    'megamenu.text.color'
  ],

  // Menu główne - link - tekst - hover
  16: [
    'menuList.link.colorOnHover',
    'menuList.subLink.colorOnHover',
    'menuList.option.colorOnHover',
    'menuList.subOption.colorOnHover',
    'megamenu.text.colorOnHover'
  ],

  // Menu główne - link - tekst - aktywny
  17: [
    'menuList.link.colorOnActive',
    'menuList.subLink.colorOnActive',
    'menuList.option.colorOnActive',
    'menuList.subOption.colorOnActive',
    'megamenu.text.colorOnActive'
  ],

  // Menu główne - link - tło
  18: [
    'menuList.link.background',
    'menuList.subLink.background',
    'menuList.option.background',
    'menuList.subOption.background',
    'megamenu.text.background'
  ],

  // Menu główne - link - tło - hover
  19: [
    'menuList.link.backgroundOnHover',
    'menuList.subLink.backgroundOnHover',
    'menuList.option.backgroundOnHover',
    'menuList.subOption.backgroundOnHover',
    'megamenu.text.backgroundOnHover'
  ],

  // Menu główne - link - tło - aktywny
  20: [
    'menuList.link.backgroundOnActive',
    'menuList.subLink.backgroundOnActive',
    'menuList.option.backgroundOnActive',
    'menuList.subOption.backgroundOnActive',
    'megamenu.text.backgroundOnActive'
  ],

  // Mega menu - pasek boczny - tło
  21: ['megamenu.aside.background.default', 'megamenu.aside.background.lg'],

  // Mega menu - pasek boczny - tytuł - tekst
  22: ['megamenu.title.color'],

  // Mega menu - pasek boczny - link - tekst
  23: ['megamenuList.text.color'],

  // Mega menu - pasek boczny - link - tekst - hover
  24: ['megamenuList.text.colorOnHover'],

  // Mega menu - pasek boczny - link - tekst - aktywny
  25: ['megamenuList.text.colorOnActive'],

  // Mega menu - pasek boczny - link - tło
  26: ['megamenuList.text.background'],

  // Mega menu - pasek boczny - link - tło - hover
  27: ['megamenuList.text.backgroundOnHover'],

  // Mega menu - pasek boczny - link - tło - aktywny
  28: ['megamenuList.text.backgroundOnActive'],

  // Tekst podstawowy
  29: [
    'bannerNavigation.title.color',
    'bannerTravel.title.color',
    'widgetList.text.color',
    'widgetItem.text.color',
    'articlesGrid.title.main.color',
    'articlesGrid.text.main.color',
    'articlesGrid.title.color',
    'articlesGrid.text.color',
    'articlesList.title.color',
    'articlesList.text.color',
    'CMS.color',
    'headline.title.color',
    'headline.subtitle.color',
    'widgetContactForm.checkboxLabel.color',
    'widgetContactForm.text.color',
    'widgetContactForm.textarea.color',
    'loadingScreen.wrapper.color',
    'errorScreen.wrapper.color',
    'loadingData.wrapper.color',
    'banner.image.background'
  ],

  // Tekst podstawowy - link
  30: [
    'bannerNavigation.link.color',
    'CMS.cms.a.color',
    'articlesGrid.link.color'
  ],

  // Tekst podstawowy - link - hover
  31: [
    'CMS.cms.a.colorOnHover',
    'bannerNavigation.link.colorOnHover',
    'articlesGrid.link.colorOnHover',
    'articlesList.link.colorOnHover',
    'articlesList.link.backgroundColorOnHover'
  ],

  // Pomarańczowe wyróżniki - ramki na bannerze, buttony, strzałeczki przy tytułach, belki “warto zobaczyć“
  // Strzałki, plakietki, “czytaj więcej“ - tło
  32: [
    'menuList.arrow.color',
    'banner.buttonIcon.color',
    'megamenu.arrow.color',
    'megamenuList.arrow.color',
    'bannerTravel.arrow.color',
    'bannerNavigation.arrow.color',
    'articlesGrid.arrow.color',
    'articlesGrid.arrow.main.color',
    'widgetList.arrow.color',
    'widgetItem.arrow.color',
    'title.arrow.color',
    'articlesList.arrow.color',
    'button.background',
    'bannerNavigation.navigationButton.background',
    'widgetDisabledFacilities.item.background',
    'widgetContactForm.button.background',
    'bannerNavigation.featured.background',
    'articlesGrid.featured.background',
    'articlesGrid.featured.main.background',
    'articlesList.featured.background',
    'bannerTravel.holder.shapeBackground',
    'menuList.wrapper.shapeBackground',
    'articlesList.itemHolder.shapeBackground',
    'articlesList.link.backgroundColor',
    'articlesGrid.itemHolder.main.shapeBackground',

    'megamenuList.wrapper.thumb',
    'bannerNavigation.panelInner.thumb',

    'headline.category.color',

    'CMS.cms.blockquote.quote.color',

    'widgetContactForm.checkbox.color'
  ],

  // Pomarańczowe wyróżniki - ramki na bannerze, buttony, strzałeczki przy tytułach, belki “warto zobaczyć“
  // Strzałki, plakietki, “czytaj więcej“ - tło - hover
  33: [
    'menuList.arrow.colorOnHover',
    'banner.buttonIcon.colorOnHover',
    'megamenu.arrow.colorOnHover',
    'megamenuList.arrow.colorOnHover',
    'bannerTravel.arrow.colorOnHover',
    'bannerNavigation.arrow.colorOnHover',
    'articlesGrid.arrow.colorOnHover',
    'articlesGrid.arrow.main.colorOnHover',
    'widgetList.arrow.colorOnHover',
    'widgetItem.arrow.colorOnHover',
    'title.arrow.colorOnHover',
    'articlesList.arrow.colorOnHover',

    'button.backgroundOnHover',
    'bannerNavigation.navigationButton.backgroundOnHover',
    'widgetDisabledFacilities.item.backgroundOnHover',
    'widgetDisabledFacilities.item.backgroundOnActive',
    'widgetContactForm.button.backgroundOnHover',

    'header.toggleButton.backgroundOnHover',
    'header.toggleButton.backgroundOnToggle'
  ],

  // Pomarańczowe wyróżniki - ramki na bannerze, buttony, strzałeczki przy tytułach, belki “warto zobaczyć“
  // Strzałki, plakietki, “czytaj więcej“ - tekst
  34: [
    'bannerNavigation.featured.color',
    'articlesGrid.featured.color',
    'articlesGrid.featured.main.color',
    'articlesList.featured.color',

    'button.color',
    'bannerNavigation.navigationButton.color',
    'widgetDisabledFacilities.item.color',
    'widgetContactForm.button.color'
  ],

  // Pomarańczowe wyróżniki - ramki na bannerze, buttony, strzałeczki przy tytułach, belki “warto zobaczyć“
  // Strzałki, plakietki, “czytaj więcej“ - tekst - hover
  35: [
    'button.colorOnHover',
    'bannerNavigation.navigationButton.colorOnHover',
    'widgetDisabledFacilities.item.colorOnHover',
    'widgetDisabledFacilities.item.colorOnActive',
    'widgetContactForm.button.colorOnHover'
  ],

  // Menu w stopce - tło
  36: ['footerMenu.wrapper.background'],

  // Menu w stopce - link - tekst
  37: ['footerMenu.item.color'],

  // Menu w stopce - link - tekst - hover
  38: ['footerMenu.item.colorOnHover'],

  // Menu w stopce - link - tekst - aktywny
  39: ['footerMenu.item.colorOnActive'],

  // Menu w stopce - link - tło
  40: ['footerMenu.item.background'],

  // Menu w stopce - link - tło - hover
  41: ['footerMenu.item.backgroundOnHover'],

  // Menu w stopce - link - tło - aktywny
  42: ['footerMenu.item.backgroundOnActive'],

  // Stopka - tło
  43: ['footerDetails.wrapper.background'],

  // Stopka - tekst
  44: ['footerDetails.text.color'],

  // Stopka - link
  45: ['footerDetails.text.link.color'],

  // Stopka - link - hover
  46: ['footerDetails.text.link.colorOnHover'],

  // Tło strony
  47: [
    'global.background',
    'loadingScreen.wrapper.primaryBackground',
    'loadingScreen.wrapper.secondaryBackground',
    'errorScreen.wrapper.primaryBackground',
    'errorScreen.wrapper.secondaryBackground',
    'loadingData.wrapper.primaryBackground',
    'loadingData.wrapper.secondaryBackground'
  ],
  // Tytuł - link - tekst
  48: ['title.link.color'],
  // Tytuł - link - tekst - hover
  49: ['title.link.colorOnHover'],
  // Paginacja - aktualna strona - tekst
  50: ['pagination.current.color'],
  // Paginacja - aktualna strona - tło
  51: ['pagination.current.background'],
  // Paginacja - strzałki - tekst
  52: ['pagination.linkArrow.color'],
  // Paginacja - strzałki - tekst - hover
  53: ['pagination.linkArrow.colorOnHover'],
  // Paginacja - strzałki - tło
  54: ['pagination.linkArrow.background'],
  // Paginacja - strzałki - tło - hover
  55: ['pagination.linkArrow.backgroundOnHover'],
  // Paginacja - link - tekst
  56: ['pagination.link.color'],
  // Paginacja - link - tekst - hover
  57: ['pagination.link.colorOnHover'],
  // Paginacja - link - tło
  58: ['pagination.link.background'],
  // Paginacja - link - tło - hover
  59: ['pagination.link.backgroundOnHover'],
  // Plakietki - tło
  60: [
    'bannerNavigation.featured.background',
    'articlesGrid.featured.background',
    'articlesGrid.featured.main.background',
    'articlesList.featured.background'
  ],
  // Banner nawigacja - obwódka artykułu
  61: [
    'bannerNavigation.item.backgroundOnSelected',
    'bannerNavigation.item.borderOnSelectedColor',
    'bannerNavigation.item.borderAfterOnSelectedColor',
    'bannerNavigation.imagebox.triangleShape.background',
    'articlesGrid.imagebox.triangleShape.background'
  ],
  //"Lista aktualności - nagłówek nad listą - kolor tekstu"
  62: ['title.text.color'],
  //"Lista aktualności - link "czytaj więcej" - kolor tekstu "
  63: ['articlesList.link.color']
};
