/* eslint-disable */
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import serverMessages from './serverMessages';

const createApolloClient = ({ uri = '/api' }) => {
  const httpLink = createUploadLink({
    uri,
    credentials: 'include'
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers
      }
    };
  });

  const fullLink = authLink.concat(httpLink);

  return new ApolloClient({
    link: ApolloLink.from([
      onError(options => {
        let { graphQLErrors, networkError, operation, response } = options;
        if (graphQLErrors)
          // eslint-disable-next-line array-callback-return
          Array.isArray(graphQLErrors) &&
            graphQLErrors.map(
              ({ message, locations, path, extensions = {} }) => {
                if (extensions.code === 401) {
                  window.location.replace('/');
                }
              }
            );
        if (Array.isArray(graphQLErrors)) {
          graphQLErrors = graphQLErrors.map(item => {
            item.message = serverMessages(options);
            return item;
          });
        }
      }),
      fullLink
    ]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'none'
      }
    }
  });
};

export default createApolloClient;
