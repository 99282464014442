import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import LoadingData from 'components/LoadingData';
import { AppSettingsProviderWrapper } from 'AppSettingsContext';
import { IntlProviderWrapper } from 'IntlContext';
import { ThemeProviderWrapper } from 'ThemeContext';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { ApolloProvider } from 'react-apollo';
import { ApolloMockingProvider } from './ApolloMockingProvider';
import ApolloClientBuilder from './ApolloClient/builder';

const System = lazy(() => import('./System'));
const GlobalFonts = lazy(() => import('components/FontAwesome'));

export const client = ApolloClientBuilder({});

export const ApolloServerProvider =
  process.env.REACT_APP_OFFLINE === 'true'
    ? ApolloMockingProvider
    : ApolloProvider;

ReactDOM.render(
  <ApolloServerProvider client={client}>
    <CookiesProvider>
      <HelmetProvider>
        <AppSettingsProviderWrapper>
          <IntlProviderWrapper>
            <ThemeProviderWrapper>
              <>
                <Suspense fallback={<LoadingData fullmode={true} />}>
                  <GlobalFonts />
                </Suspense>
                <Router>
                  <Suspense fallback={<LoadingData fullmode={true} />}>
                    <System />
                  </Suspense>
                </Router>
              </>
            </ThemeProviderWrapper>
          </IntlProviderWrapper>
        </AppSettingsProviderWrapper>
      </HelmetProvider>
    </CookiesProvider>
  </ApolloServerProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
