import React, { createContext, useReducer } from 'react';

import config from 'config';
export const Context = createContext([{}, function() {}]);

const initAppSettingsState = () => {
  return {
    isLoaded: false,
    isError: false,
    name: undefined,
    domain: undefined,
    domainUrl: undefined,
    lang: config.lang,
    theme: undefined,
    themeColors: [],
    orientation: config.orientation,
    highContrast: config.highContrast,
    fontSizeScale: config.fontSizeScale,
    mourning: config.mourning,
    socials: [],
    settings: [],
    menu: [],
    logo: undefined,
    navMenu: [],
    navMenuLeft: [],
    cookies: {},
    copyright: {},
    footerMenu: [],
    facebook_api_key: undefined,
    banner_options: {},
    popup: [],
    footer: {
      0: undefined,
      1: undefined,
      2: undefined,
      3: undefined
    }
  };
};
const appSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      return state;
    }
    case 'SET_APP_SETTINGS_PENDING': {
      return {
        ...state,
        isLoaded: false,
        isError: false
      };
    }
    case 'SET_APP_SETTINGS_FULFILLED': {
      const {
        name,
        domain,
        domainUrl,
        lang,
        theme,
        themeColors,
        orientation,
        mourning,
        menu,
        logo,
        cookies,
        copyright,
        footerMenu,
        facebook_api_key,
        banner_options
      } = action.payload;
      return {
        ...state,
        isLoaded: true,
        isError: false,
        name: name || state.name,
        domain: domain || state.domain,
        domainUrl: domainUrl || state.domainUrl,
        lang: lang || state.lang,
        theme: theme || state.theme,
        themeColors: themeColors || state.themeColors,
        orientation: orientation || state.orientation,
        mourning: mourning || state.mourning,
        menu: menu || state.menu,
        logo: logo || state.logo,
        cookies: cookies || state.cookies,
        copyright: copyright || state.copyright,
        footerMenu: footerMenu || state.footerMenu,
        facebook_api_key: facebook_api_key || state.facebook_api_key,
        banner_options: banner_options || state.banner_options
      };
    }
    case 'SET_APP_SETTINGS_SOCIALS_FULFILLED': {
      const { socials } = action.payload;
      return {
        ...state,
        socials: socials || state.socials
      };
    }
    case 'SET_APP_SETTINGS_SETTINGS_FULFILLED': {
      const { settings } = action.payload;
      return {
        ...state,
        settings: settings || state.settings
      };
    }
    case 'SET_APP_SETTINGS_NAV_MENU_FULFILLED': {
      const { navMenu } = action.payload;
      return {
        ...state,
        navMenu: navMenu || state.navMenu
      };
    }
    case 'SET_APP_SETTINGS_NAV_MENU_LEFT_FULFILLED': {
      const { navMenuLeft } = action.payload;
      return {
        ...state,
        navMenuLeft: navMenuLeft || state.navMenuLeft
      };
    }
    case 'SET_APP_SETTINGS_POPUP_FULFILLED': {
      const { popup } = action.payload;
      return {
        ...state,
        popup: popup || state.popup
      };
    }
    case 'SET_APP_SETTINGS_FOOTER_COLUMN_FULFILLED': {
      const { column, footerColumn } = action.payload;
      return {
        ...state,
        footer: { ...state.footer, [column]: footerColumn } || state.footer
      };
    }
    case 'SET_APP_SETTINGS_REJECTED': {
      return {
        ...state,
        isLoaded: true,
        isError: true
      };
    }
    case 'TOGGLE_HIGH_CONTRAST': {
      return {
        ...state,
        highContrast: !state.highContrast
      };
    }
    case 'REDUCE_FONT_SIZE': {
      return {
        ...state,
        fontSizeScale:
          state.fontSizeScale > 0.9
            ? state.fontSizeScale - 0.1
            : state.fontSizeScale
      };
    }
    case 'RESET_FONT_SIZE': {
      return {
        ...state,
        fontSizeScale: 1
      };
    }
    case 'INCREASE_FONT_SIZE': {
      return {
        ...state,
        fontSizeScale:
          state.fontSizeScale < 1.2
            ? state.fontSizeScale + 0.1
            : state.fontSizeScale
      };
    }
    default:
      return state;
  }
};

const AppSettingsProviderWrapper = ({ children }) => (
  <Context.Provider
    value={useReducer(appSettingsReducer, initAppSettingsState())}
  >
    {children}
  </Context.Provider>
);

export { AppSettingsProviderWrapper, Context as AppSettingsContext };
