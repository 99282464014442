import ARTICLES_LIST_SHAPE from '../Images/Shapes/theme-two/articlesList-bg-shape.svg';
import FEATURED_SHAPE from '../Images/Shapes/theme-two/featured-bg-shape.svg';
import { darken, lighten, rgba } from '../Mixins.style';
import { Colors } from '../Variables.style';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

const theme = {
  global: {
    background: Colors.white,
    fontFamily: 'Lato, sans-serif'
  },
  articlePage: {
    background: 'transparent'
  },
  fontface: {
    primaryFont: 'Lato',
    primaryFontName: 'Lato',
    secondaryFont: 'Anton',
    secondaryFontName: 'Anton'
  },
  title: {
    wrapper: {
      alignItems: 'stretch',
      margin: {
        default: '0 0 20px 0',
        md: '0 0 10px 0'
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      background: 'transparent',
      isBackground: true
    },
    text: {
      padding: {
        default: ' 5px 20px',
        md: '5px 15px'
      },
      color: Colors.curiousBlue,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 3.6,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      margin: {
        default: '2px 2px 2px auto',
        md: '2px 2px 2px auto'
      },
      padding: {
        default: '5px',
        md: '5px'
      },
      borderRadius: {
        default: 0,
        md: 0,
        sm: 0
      },
      background: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 900,
      triangleShape: undefined
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      margin: {
        default: '0 0 0 5px',
        md: '0 0 0 5px'
      },
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  button: {
    padding: {
      default: '15px 25px',
      md: '5px 15px'
    },
    background: Colors.tango,
    backgroundOnHover: darken(Colors.tango, 50),
    color: Colors.white,
    colorOnHover: Colors.white,
    fontFamily: 'Lato, sans-serif',
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1,
    fontWeight: 700
  },
  buttonIcon: {
    position: 'right-center',
    minWidth: 'auto',
    faIcon: faAngleDoubleRight,
    minHeight: 'auto',
    margin: {
      default: '0 5px 0 5px',
      md: '0 5px 0 5px'
    },
    borderRadius: {
      default: 0,
      md: 0
    },
    background: 'transparent',
    color: 'inherit'
  },
  loadingScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.bigStone
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  errorScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.bigStone
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  loadingData: {
    wrapper: {
      primaryBackground: 'transparent',
      secondaryBackground: 'transparent',
      color: Colors.bigStone
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  toolbar: {
    wrapper: {
      color: Colors.white
    },
    item: {
      left: {
        padding: {
          default: '0 2px',
          md: '0 2px'
        },
        background: Colors.bigStone
      },
      right: {
        padding: {
          default: '0 2px',
          md: '0 2px'
        },
        background: Colors.bigStone
      }
    }
  },
  toolbarSocial: {
    item: {
      color: Colors.white,
      colorOnHover: 'inherit',
      background: Colors.transparent,
      backgroundOnHover: Colors.tango,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  toolbarSettings: {
    item: {
      color: Colors.white,
      colorOnHover: 'inherit',
      background: Colors.transparent,
      backgroundOnHover: Colors.tango,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  header: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`,
      padding: {
        default: '15px 0 0',
        lg: '10px 0',
        md: '5px 0',
        sm: '2px 0'
      },
      zIndex: 2
    },
    box: {
      gridGap: 0
    },
    toggleButton: {
      color: Colors.bigStone,
      colorOnHover: Colors.white,
      backgroundOnHover: Colors.tango,
      colorOnToggle: Colors.white,
      backgroundOnToggle: Colors.tango
    },
    logo: {
      image: {
        width: 1395,
        height: 690,
        widthTablet: 969,
        heightTablet: 480,
        widthMobile: 767,
        heightMobile: 480,
        crop: 0
      }
    }
  },
  blackHole: {
    wrapper: {
      color: Colors.bigStone
    },
    title: {
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.2,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  banner: {
    wrapper: {
      marginTop: '10px'
    },
    box: {
      boxShadow: `0 0 36px 0 ${rgba(Colors.black, 0.16)}`
    },
    item: {
      height: {
        default: '690px',
        lg: '480px'
      }
    },
    image: {
      width: 1395,
      height: 690,
      widthTablet: 969,
      heightTablet: 480,
      widthMobile: 767,
      heightMobile: 480,
      background: Colors.bigStone
    },
    contentInner: {
      gridRows: {
        default: '1fr 90px',
        lg: '1fr'
      }
    },
    details: {
      justifyContent: 'flex-end',
      padding: {
        default: '25px 0 55px',
        md: '15px 0'
      }
    },
    detailsInner: {
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent'
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '0',
        md: '0'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      color: Colors.white,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    button: {
      faIcon: faAngleDoubleRight
    },
    buttonIcon: undefined,
    navigation: {
      top: 0,
      bottom: '90px'
    },
    travel: {
      margin: {
        default: '-90px 0 0',
        lg: 0
      }
    }
  },
  bannerTravel: {
    holder: {
      shape: undefined
    },
    wrapper: {
      margin: {
        default: 0,
        lg: 0
      },
      borderRadius: { default: '4px', lg: 0 },
      background: Colors.white,
      boxShadow: {
        default: `0 0 3px 0 ${rgba(Colors.black, 0.16)}`,
        lg: `none`
      }
    },
    box: {
      padding: {
        default: '10px 0',
        lg: 0
      }
    },
    item: {
      padding: {
        default: '10px 20px',
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    title: {
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      bannerTitleSpacing: '3rem',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      faIcon: faAngleDoubleRight
    }
  },
  bannerNavigation: {
    wrapper: {
      top: '0',
      transform: 'none',
      height: '100%'
    },
    panel: {
      background: rgba(Colors.black, 0.6)
    },
    panelInner: {
      margin: 0,
      padding: '0 15px',
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    navigationButton: {
      position: 'relative',
      width: '36px',
      height: '36px',
      isBackground: false,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.white,
      colorOnHover: Colors.white,
      prev: {
        top: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: 0
      },
      next: {
        bottom: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: 0
      }
    },
    box: {
      gridGap: '20px',
      padding: '0 0 0 15px'
    },
    item: {
      minHeight: '150px',
      padding: '0',
      background: 'transparent',
      backgroundOnSelected: Colors.tango,
      border: '5px solid transparent',
      borderOnSelected: `5px solid`,
      borderOnSelectedColor: Colors.tango,
      borderAfterOnSelected: `14px solid`,
      borderAfterOnSelectedColor: Colors.tango
    },
    itemInner: {
      gridColumns: '200px 1fr',
      background: Colors.white
    },
    imagebox: {
      width: '200px',
      minHeight: '100%',
      height: '100%',
      margin: 0,
      triangleShape: undefined
    },
    image: {
      width: 200,
      height: 150,

      borderRadius: '4px 0 0 4px'
    },
    featured: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'initial',
      isText: true,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '4px 5px',
      borderRadius: 0,
      background: Colors.tango,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      width: 'auto',
      height: 'auto',
      margin: '0 5px 0 0',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: 1
    },
    details: {
      padding: '20px',
      triangleShape: undefined
    },
    title: {
      margin: '0 0 15px 0',
      padding: 0,
      color: Colors.bigStone,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.8,
      lineHeight: 1.2,
      fontWeight: 700
    },
    link: {
      isText: true,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700
    },
    arrow: {
      position: 'right-center',
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: 0,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  headline: {
    category: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.tango,
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    title: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    subtitle: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 3.6,
        lg: 2.8,
        md: 2.2,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    imagebox: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      }
    },
    image: {
      width: 1395,
      height: 480,
      widthTablet: 922,
      heightTablet: 317,
      widthMobile: 720,
      heightMobile: 180,

      borderRadius: {
        default: 0,
        md: 0
      }
    }
  },
  articleGallery: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    caption: {
      fontFamily: 'Anton, sans-serif'
    },
    image: {
      width: 230,
      height: 173,
      widthTablet: 307,
      heightTablet: 307,
      widthMobile: 360,
      heightMobile: 360
    }
  },
  widgets: {
    wrapper: {
      gridGap: {
        default: '15px',
        md: '30px'
      }
    }
  },
  widgetDisabledFacilities: {
    item: {
      color: Colors.white,
      colorOnHover: Colors.white,
      colorOnActive: Colors.white,
      background: Colors.tango,
      backgroundOnHover: darken(Colors.tango, 50),
      backgroundOnActive: darken(Colors.tango, 50),
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetTitle: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '5px 20px',
        md: '5px 15px'
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      background: 'transparent',
      isBackground: true,
      color: Colors.curiousBlue,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: true,
      background: Colors.blizzardBlue
    }
  },
  widgetSchoolMenu: {
    backgroundColor: Colors.casper,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.ziggurat,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      backgroundColor: Colors.ziggurat,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    mealsBox: {
      padding: {
        default: '0 20px 18px'
      }
    },
    meal: {
      margin: {
        default: '0 0 24px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      border: `1px dashed ${Colors.white}`
    },
    innerTitle: {
      margin: {
        default: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    decoration: {
      color: Colors.orange,
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.8
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    footer: {
      backgroundColor: Colors.blueberry,
      padding: {
        default: '20px 64px 20px 20px'
      }
    },
    footerText: {
      margin: { default: '0' },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetLessonPlan: {
    backgroundColor: Colors.lightGreen,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.jetStream,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    selectContainer: {
      padding: {
        default: '0 24px 40px'
      },
      backgroundColor: Colors.jetStream
    },
    selectText: {
      margin: {
        default: '0 0 4px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    select: {
      padding: {
        default: '9px 17px 9px 12px'
      },
      border: `1px solid ${Colors.acapulco}`,
      borderRadius: '4px',
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '5px 0 24px 0'
      },
      backgroundColor: Colors.jetStream,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    table: {
      padding: {
        default: '0 20px 34px'
      }
    },
    content: {
      color: Colors.blueberry,
      secondColor: Colors.white,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 700,
      lineHeight: 1.2,
      row: {
        padding: {
          default: '16px 4px 16px 4px'
        },
        border: `1px dashed ${Colors.white}`
      }
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetSpacer: {
    spacer: {
      background: Colors.blizzardBlue
    }
  },
  widgetList: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '5px 20px',
        md: '5px 15px'
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      background: 'transparent',
      isBackground: true,
      color: Colors.curiousBlue,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      }
    }
  },
  widgetItem: {
    item: {
      minHeight: {
        default: 'initial',
        md: 'initial'
      },
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: '20px 0',
        md: '10px 0'
      },
      gridGap: {
        default: '10px',
        md: '10px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      border: `1px solid ${Colors.blizzardBlue}`,
      borderFirstTop: `1px solid ${Colors.blizzardBlue}`,
      borderLastBottom: `1px solid ${Colors.blizzardBlue}`,
      boxShadow: 'none'
    },
    imagebox: {
      margin: {
        default: 0,
        md: 0
      }
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      margin: {
        default: '0',
        md: '0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  widgetContactForm: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '5px 20px',
        md: '5px 15px'
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      background: 'transparent',
      isBackground: true,
      color: Colors.curiousBlue,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: true,
      background: Colors.blizzardBlue
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      },
      padding: {
        default: '20px 0',
        md: '10px 0'
      }
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    textarea: {
      padding: {
        default: '10px',
        md: '5px'
      },
      border: '#ebedf0',
      background: Colors.white,
      color: Colors.bigStone,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      boxShadow: 'none'
    },
    checkboxLabel: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkbox: {
      border: '#ebedf0',
      background: Colors.white,
      color: Colors.bigStone,
      boxShadow: 'none'
    },
    button: {
      padding: {
        default: '0 10px',
        md: '0 5px'
      },
      background: Colors.tango,
      backgroundOnHover: darken(Colors.tango, 50),
      color: Colors.white,
      colorOnHover: Colors.white,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetImage: {
    image: {}
  },
  widgetAdvertisement: {
    image: {}
  },
  widgetBanner: {
    image: {},
    title: {
      color: Colors.white,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetIcon: {
    item: {
      color: Colors.bigStone
    },
    text: {
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetLuckyNumber: {
    title: {
      color: Colors.white,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 7.2,
        lg: 6.4,
        md: 5.2,
        sm: 4.8
      },
      lineHeight: 1.2,
      fontWeight: 700,
      fontStyle: 'italic'
    }
  },
  widgetPartners: {
    title: {
      margin: {
        default: '0 0 40px 0',
        md: '0 0 20px 0'
      },
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 3.0,
        lg: 3.0,
        md: 2.0,
        sm: 2.0
      },
      fontWeight: 200,
      lineHeight: 1.2
    }
  },
  widgetCalendar: {
    backgroundColor: Colors.sprout,
    container: {
      padding: {
        default: '36px 26px 9px 26px'
      }
    },
    box: {
      margin: {
        default: '0 0 22px 0'
      }
    },
    calendarTop: {
      margin: {
        default: '0 0 20px 0'
      }
    },
    arrowButton: {
      fontSize: {
        default: 2.7,
        sm: 2.0
      }
    },
    monthYear: {
      color: Colors.black,
      fontSize: {
        default: 2.3,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    dayNamesWrapper: {
      margin: {
        default: '0 0 12px 0'
      }
    },
    dayName: {
      color: Colors.black,
      fontSize: {
        default: 1.4,
        xs: 1.2
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    table: {
      rowGap: '7px',
      columnGap: '1px'
    },
    day: {
      padding: {
        default: '7px 0 7px 0',
        sm: '5px 0 5px 0'
      },
      backgroundColor: {
        default: Colors.white,
        weekend: Colors.mercury2,
        clickable: Colors.camouflageGreen,
        hover: lighten(Colors.camouflageGreen, 10),
        selected: Colors.shiraz
      },
      color: Colors.black,
      borderColor: Colors.dove,
      fontSize: {
        default: 2.3,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif',
      width: {
        default: '36px',
        sm: '25px'
      }
    },
    event: {
      color: Colors.white,
      margin: {
        default: '0 0 20px 0'
      },
      padding: {
        default: '0 50px 0 0',
        sm: '0 30px 0 0'
      },
      after: {
        padding: '14px 17px 14px 17px',
        paddingSm: '7px 9px 7px 9px',
        backgroundColor: Colors.doveGray,
        backgroundColorOnHover: darken(Colors.doveGray, 50),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Metropolis, sans-serif',
        fontSize: 1.4,
        fontSizeSm: 1.2,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    title: {
      margin: {
        default: '0 0 5px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 700,
      fontFamily: 'Metropolis, sans-serif'
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 400,
      fontFamily: 'Metropolis, sans-serif'
    },
    link: {
      padding: {
        default: '14px 12px 14px 12px',
        sm: '7px 6px 7px 6px'
      },
      backgroundColor: {
        default: Colors.doveGray,
        hover: darken(Colors.doveGray, 50)
      },
      color: Colors.white,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: 1.4,
      fontWeight: 700
    }
  },
  articlesList: {
    wrapper: {
      margin: {
        default: '0 0 15px 0',
        lg: '0 0 15px 0',
        md: '0 0 5px 0',
        sm: '0 0 5px 0'
      }
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      right: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      }
    },
    item: {
      gridColumns: {
        default: '33.33% 1fr',
        lg: '1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      gridGap: '10px',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '25px 0',
        lg: '25px 0',
        md: '15px 0',
        sm: '15px 0'
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      border: {
        left: 0,
        right: 0,
        bottom: 0,
        top: `1px solid ${Colors.blizzardBlue}`,
        lastOfType: {
          bottom: `1px solid ${Colors.blizzardBlue}`
        }
      },
      boxShadow: 'none'
    },
    imagebox: {
      maxWidth: '100%',
      height: 'auto',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      triangleShape: undefined
    },
    image: {
      width: 570,
      height: 428,
      widthTablet: 456,
      heightTablet: 342,
      widthMobile: 720,
      heightMobile: 540,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    featured: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'initial',
      isText: true,
      minWidth: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      minHeight: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      padding: '4px 5px',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: Colors.tango,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      width: 'auto',
      height: 'auto',
      margin: '0 5px 0 0',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: {
        default: 1,
        lg: 1,
        md: 1,
        sm: 1
      }
    },
    details: {
      height: 'auto',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      triangleShape: undefined
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  articlesGrid: {
    wrapper: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: Colors.blizzardBlue,
      padding: {
        default: '55px 0 25px',
        md: '25px 0 15px'
      },
      gridTemplateColumns: {
        default: '1fr 1fr 1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr 1fr'
      },
      gridGap: {
        default: '40px 20px',
        lg: '40px 20px',
        md: '20px',
        sm: '20px'
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        margin: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        },
        gridArea: {
          default: '1 / 1 / 2 / 5',
          lg: '1 / 1 / 2 / 3',
          md: '1 / 1 / 2 / 3',
          sm: '1 / 1 / 2 / 3'
        },
        shape: undefined,
        left: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    item: {
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      border: {
        left: 'none',
        right: 'none',
        bottom: 'none',
        top: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      boxShadow: 'none',
      main: {
        gridColumns: {
          default: '1fr 1fr',
          lg: '1fr',
          md: '1fr 1fr',
          sm: '1fr'
        },
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: 0,
          md: 0
        },
        background: 'transparent',
        borderRadius: {
          default: 0,
          md: 0
        },
        boxShadow: 'none'
      }
    },
    imagebox: {
      maxWidth: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        maxWidth: '100%',
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    image: {
      width: 218,
      height: 164,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 263,

      borderRadius: {
        default: 0,
        md: 0
      },
      main: {
        width: 515,
        height: 386,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 720,
        heightMobile: 540,

        borderRadius: {
          default: 0,
          md: 0
        }
      }
    },
    featured: {
      position: undefined,
      isText: true,
      minWidth: {
        default: '100%',
        lg: '100%',
        md: '100%',
        sm: '100%'
      },
      minHeight: {
        default: '20px',
        lg: '20px',
        md: '20px',
        sm: '20px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: Colors.tango,
      color: Colors.white,
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      top: {
        default: '0',
        lg: '0',
        md: '0',
        sm: '0'
      },
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      fontWeight: 700,
      main: {
        position: undefined,
        isText: true,
        minWidth: {
          default: '100%',
          lg: '100%',
          md: '100%',
          sm: '100%'
        },
        minHeight: {
          default: '20px',
          lg: '20px',
          md: '20px',
          sm: '20px'
        },
        top: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        },
        bottom: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        },
        borderRadius: {
          default: 0,
          md: 0
        },
        background: Colors.tango,
        color: Colors.white,
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1,
        lg: 1,
        md: 1,
        sm: 1
      },
      margin: '0 5px 0 0',
      color: Colors.white,
      faIcon: faAngleDoubleRight,
      position: 'relative',
      main: {
        fontSize: {
          default: 1,
          lg: 1,
          md: 1,
          sm: 1
        },
        faIcon: faAngleDoubleRight,
        margin: '0 5px 0 0',
        color: Colors.white
      }
    },
    details: {
      height: '100%',
      alignItems: 'flex-start',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '10px 0 0 0',
        lg: '10px 0 0 0',
        md: '5px 0 0 0',
        sm: '5px 0 0 0'
      },
      main: {
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '25px',
          lg: '25px',
          md: '15px',
          sm: '15px 0 0 0'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      wordBreak: 'break-word',
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      main: {
        fontSize: {
          default: 2.8,
          lg: 2.8,
          md: 2.4,
          sm: 1.4
        }
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      isText: true,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        isText: true,
        fontSize: {}
      }
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50),
      main: {}
    }
  },
  pagination: {
    current: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      color: Colors.tango,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.1,
        lg: 2.1,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    link: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: Colors.tango,
      color: Colors.bigStone,
      colorOnHover: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    linkArrow: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50),
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: 'inherit',
      colorOnHover: 'inherit',
      faIconRight: faAngleDoubleRight,
      faIconLeft: faAngleDoubleLeft
    }
  },
  navMenu: {
    item: {
      padding: {
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    title: {
      color: Colors.curiousBlue,
      colorOnHover: darken(Colors.curiousBlue, 50),
      fontSize: {
        default: 1.4,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  menuList: {
    wrapper: {
      position: undefined,
      shape: undefined,
      padding: { default: 0, lg: 0 },
      borderRadius: { default: 0, lg: 0 },
      background: { default: 'transparent', lg: 'transparent' }
    },
    content: {
      position: undefined,
      margin: { default: '2px 0 -2px' }
    },
    link: {
      padding: {
        default: '5px 15px 20px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      border: Colors.blizzardBlue,
      color: Colors.bigStone,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.2,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    subLink: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blizzardBlue,
      color: Colors.bigStone,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      background: 'transparent',
      backgroundOnHover: Colors.blizzardBlue,
      backgroundOnActive: Colors.blizzardBlue,
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    option: {
      padding: {
        default: '5px 15px 20px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      borderRadius: { default: 0, lg: 0 },
      border: Colors.blizzardBlue,
      color: Colors.bigStone,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      background: 'transparent',
      backgroundOnHover: Colors.white,
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`,
      boxShadowOnActive: `none`,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.2,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    subOption: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blizzardBlue,
      color: Colors.bigStone,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      background: 'transparent',
      backgroundOnHover: Colors.blizzardBlue,
      backgroundOnActive: Colors.blizzardBlue,
      boxShadowOnHover: `none`,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    itemContent: {
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    arrow: {
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      faIcon: faAngleDoubleRight
    }
  },
  megamenu: {
    wrapper: {
      padding: {
        default: 0,
        lg: '20px 0 0',
        md: '10px 0'
      },
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    main: {
      padding: {
        default: '20px 20px 0',
        lg: 0,
        md: 0
      }
    },
    aside: {
      boxShadow: {
        default: `inset 12px 0 12px -6px ${rgba(Colors.black, 0.16)}`,
        lg: 'none'
      },
      maxWidth: '346px',
      padding: {
        default: '20px 40px 0',
        lg: 0,
        md: 0
      },
      background: {
        default: rgba(Colors.blizzardBlue, 0.25),
        lg: 'transparent'
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    item: {
      padding: {
        default: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    text: {
      color: Colors.bigStone,
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  megamenuList: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    item: {
      padding: {
        default: '10px 0',
        lg: '10px 0',
        md: '5px 0'
      }
    },
    text: {
      color: Colors.bigStone,
      colorOnHover: Colors.bigStone,
      colorOnActive: Colors.bigStone,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent',
      fontFamily: 'Anton, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },

  articlesGridNew: {
    wrapper: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: 'transparent',
      padding: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      gridTemplateColumns: {
        default: '1fr 1fr 1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      columnGap: {
        default: '25px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      },
      rowGap: {
        default: '10px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      }
    },
    itemHolder: {
      shape: undefined,
      main: {
        gridArea: {
          default: '1 / 1 /4 / 3',
          lg: '2 / 1 / 3 /3',
          md: '2 / 1 / 3 /3',
          sm: '2 / 1 / 2 /2'
        },
        gridAreaReverted: {
          default: '1 / 3 / 4 / 5',
          lg: '2 / 1 / 3 /3',
          md: '2 / 1 / 3 /3',
          sm: '2 / 1 / 2 /2'
        },
        margin: {
          default: '-35px 0 0 0',
          lg: '0 0 0  0',
          md: '0 0 0 0',
          sm: '0 0 0 0'
        },
        shape: ARTICLES_LIST_SHAPE,
        shapeBackground: Colors.brinkPink
      }
    },
    item: {
      color: `${Colors.white} !important`,
      backgroundColorOnHoverLink: darken(Colors.sprout, 25),
      backgroundColorOnHoverLinkMain: darken(Colors.white, 25),
      colorOnHoverLinkMain: Colors.white,
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      boxShadow: 'none',
      border: {
        left: 'none',
        right: 'none',
        bottom: 'none',
        top: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      main: {
        gridColumns: {
          default: '1fr',
          lg: '1fr',
          md: '1fr',
          sm: '1fr'
        },
        margin: {
          default: '0 0 0 0',
          lg: 0,
          md: '0 0 0 0',
          sm: 0
        },
        padding: {
          default: '10px',
          lg: '25px 10px 10px 10px',
          md: '25px 10px 10px 10px',
          sm: '10px 10px 10px 10px'
        },
        background: Colors.sprout,
        borderRadius: {
          default: '0',
          md: '0'
        },
        boxShadow: `none`
      }
    },
    imagebox: {
      maxWidth: 'initial',
      order: '1',

      margin: {
        default: '0 0 10px 0',
        lg: '0 0 10px 0',
        md: '0 0 10px 0',
        sm: '0 0 10px 0'
      },
      main: {
        maxWidth: 'initial',
        margin: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        }
      }
    },
    image: {
      width: 248,
      height: 186,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 262,

      borderRadius: {
        default: '0',
        md: '0'
      },
      main: {
        width: 528,
        height: 396,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 730,
        heightMobile: 547,

        borderRadius: {
          default: '0',
          md: '0'
        }
      }
    },
    featured: {
      position: 'top-right-edge',
      shape: FEATURED_SHAPE,
      isText: false,
      minWidth: {
        default: '51px',
        lg: '51px',
        md: '41px',
        sm: '41px'
      },
      minHeight: {
        default: '44px',
        lg: '44px',
        md: '34px',
        sm: '34px'
      },
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      background: Colors.grey,
      color: Colors.white,
      top: {
        default: '-15px',
        lg: '-15px',
        md: '-15px',
        sm: '-15px'
      },
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      right: '-20px',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        top: {
          default: '-15px',
          lg: '-15px',
          md: '-15px',
          sm: '-15px'
        },
        bottom: {
          default: 'unset',
          lg: 'unset',
          md: 'unset',
          sm: 'unset'
        },
        position: 'top-right-edge',
        shape: FEATURED_SHAPE,
        isText: false,
        minWidth: {
          default: '92px',
          lg: '82px',
          md: '72px',
          sm: '62px'
        },
        minHeight: {
          default: '72px',
          lg: '62px',
          md: '52px',
          sm: '42px'
        },
        borderRadius: {
          default: '100%',
          md: '100%'
        },
        background: 'transparent',
        color: Colors.white,
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      margin: '0 0 0 25%',
      color: Colors.white,
      faIcon: 'none',
      position: 'relative',
      main: {
        fontSize: {
          default: 2.5,
          lg: 2.5,
          md: 2,
          sm: 2
        },
        faIcon: 'none',
        margin: '0 0 0 25%',
        color: Colors.white
      }
    },
    details: {
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        color: Colors.white,
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '15px 50px 0 15px',
          lg: '10px 50px 10px 10px',
          md: '15px',
          sm: '0 15px 15px'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      wordBreak: 'break-word',
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      main: {
        color: Colors.white,
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        }
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: 'inherit',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      position: 'absolute',
      padding: '10px 30px 10px 25px',
      backgroundColor: Colors.sprout,
      transform: 'rotate(-90deg) translateX(86px)',
      isText: false,
      color: Colors.white,
      colorOnHover: lighten(Colors.black, 50),
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        isText: true,
        fontSize: {},
        color: Colors.sprout,
        backgroundColor: Colors.white,
        transform: 'rotate(-90deg) translateX(8px)',
        transformLg: 'rotate(-90deg) translateX(-180px)'
      }
    },
    arrow: {
      minWidth: '0',
      minHeight: '0',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white,
      faIcon: 'none',
      main: {
        fontSize: {}
      }
    }
  },
  galleriesGrid: {
    box: {
      columnGap: '25px',
      rowGap: '17px'
    },
    imagebox: {
      margin: {
        default: '0 0 10px 0'
      },
      after: {
        padding: '8px 23px 8px 23px',
        backgroundColor: Colors.sprout,
        backgroundColorOnHover: darken(Colors.sprout, 25),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 1.4,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    text: {
      color: Colors.black,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      },
      fontWeight: 700,
      lineHeight: 1.2
    }
  },
  cookies: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 -3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    text: {
      margin: {
        default: '15px 0',
        md: '5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  CMS: {
    color: Colors.bigStone,
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1.2,
    fontWeight: 400,
    cms: {
      h1: {
        fontFamily: 'Anton, sans-serif',
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h2: {
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h3: {
        fontSize: {
          default: 2.2,
          lg: 2.0,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h4: {
        fontSize: {
          default: 2.0,
          lg: 1.8,
          md: 1.8,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h5: {
        fontSize: {
          default: 1.8,
          lg: 1.6,
          md: 1.6,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h6: {
        fontSize: {
          default: 1.6,
          lg: 1.6,
          md: 1.4,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      mark: {
        background: Colors.candlelight
      },
      ul: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      ol: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      table: {
        border: Colors.bigStone
      },
      tr: {
        background: Colors.white,
        backgroundAlt: Colors.mercury
      },
      th: {
        border: Colors.bigStone
      },
      td: {
        border: Colors.bigStone
      },
      a: {
        color: Colors.tango,
        colorOnHover: darken(Colors.tango, 50)
      },

      blockquote: {
        fontFamily: 'Anton, sans-serif',
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400,
        quote: {
          margin: {
            default: '-7.0rem 20px 0 0',
            md: '-5.0rem 20px 0 0',
            sm: '-4.0rem 20px 0 0'
          },
          color: Colors.tango,
          background: Colors.waikawaGray,
          fontFamily: 'Anton, sans-serif',
          fontSize: {
            default: 18.0,
            lg: 18.0,
            md: 14.0,
            sm: 10.0
          },
          lineHeight: 1,
          fontWeight: 400
        }
      }
    }
  },
  modal: {
    holder: {
      background: rgba(Colors.black, 0.6),
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    panel: {
      background: Colors.white,
      shadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    close: {
      color: Colors.bigStone,
      colorOnHover: Colors.tango
    }
  },
  popup: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    }
  },
  footerMenu: {
    wrapper: {
      background: Colors.tango,
      padding: {
        default: '2px 0',
        md: '2px 0'
      }
    },
    item: {
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      colorOnActive: darken(Colors.white, 50),
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent'
    },
    text: {
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  footerDetails: {
    wrapper: {
      background: Colors.astronautBlue,
      padding: {
        default: '45px 0 30px',
        md: '25px 0 15px'
      }
    },
    column: {
      border: `2px solid ${rgba(Colors.black, 0.3)}`
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  },
  footerCopyright: {
    wrapper: {
      background: Colors.tiber,
      padding: {
        default: '12px 0',
        md: '6px 0'
      }
    },
    text: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1,
      fontWeight: 400,
      textAlign: {
        default: 'left',
        sm: 'center'
      },
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  }
};

export default theme;
