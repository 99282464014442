module.exports = `
  type News {
    news_id: Int
    portal_id: Int
    category: NewsCategory
    news_category_id: Int
    name: String
    meta_title: String
    meta_description: String
    url: String
    url_canonic: String
    date: String
    publication_from: String
    publication_to: String
    active:Boolean
    promoted:Boolean
    promoted_to:String
    content_short:String
    content:String
    image_main_url:String
    image_header_url:String
    done: Boolean
    isFeatured: Boolean
  }

  type NewsList {
    category: NewsCategory
    slug: String
    pages: Int
    articles: [News]
  }


  input News_portals {
    portal_id: Int!
    news_category: [Int!]
  }

  enum NewsOrderBy {
    name
    date
    publication_from
    promoted
    active
  }

  enum NewsImageType {
    image_main
    image_header
  }
`;
