import themeOne from './themeOne';
import themeTwo from './themeTwo';
import themeThree from './themeThree';
import themeFour from './themeFour';
import themeFive from './themeFive';
import themeSix from './themeSix';
export default {
  themeOne,
  themeTwo,
  themeThree,
  themeFour,
  themeFive,
  themeSix
};
