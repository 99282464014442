let QueryTypeDefs = require("./_Query");
let MutationTypeDefs = require("./_Mutation");
let SuperadminTypeDefs = require("./Superadmin");
let AdminTypeDefs = require("./Admin");
let ArticleTypeDefs = require("./Article");
let BasicTypeDefs = require("./Basic");
let CopyrightTypeDefs = require("./Copyright");
let TokenTypeDefs = require("./Token");
let FileTypeDefs = require("./File");
let GalleryTypeDefs = require("./Gallery");
let NewsTypeDefs = require("./News");
let NewsCategoryTypeDefs = require("./NewsCategory");
let PageTypeDefs = require("./Page");
let PortalTypeDefs = require("./Portal");
let ThemeTypeDefs = require("./Theme");
let WatermarkPositionTypeDefs = require("./Watermark");
let WidgetTypeDefs = require("./Widget");

let Scalars = `
  scalar Upload
  scalar WidgetData
  scalar MenuData
`;

let typeDefs = [
  Scalars,
  QueryTypeDefs,
  MutationTypeDefs,
  AdminTypeDefs,
  ArticleTypeDefs,
  BasicTypeDefs,
  CopyrightTypeDefs,
  FileTypeDefs,
  GalleryTypeDefs,
  PageTypeDefs,
  PortalTypeDefs,
  NewsTypeDefs,
  NewsCategoryTypeDefs,
  SuperadminTypeDefs,
  ThemeTypeDefs,
  TokenTypeDefs,
  WidgetTypeDefs,
  WatermarkPositionTypeDefs,
];

module.exports = typeDefs;
