import ARTICLES_LIST_SHAPE from '../Images/Shapes/theme-two/articlesList-bg-shape.svg';
import FEATURED_SHAPE from '../Images/Shapes/theme-two/featured-bg-shape.svg';
import MENU_LIST_SHAPE from '../Images/Shapes/theme-two/menuList-bg-shape.svg';
import TRAVEL_SHAPE from '../Images/Shapes/theme-two/travel-bg-shape.svg';
import { darken, lighten, rgba } from '../Mixins.style';
import { Colors } from '../Variables.style';
import {
  faAngleDoubleRight,
  faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons';
import { nonExecutableDefinitionMessage } from 'graphql/validation/rules/ExecutableDefinitions';

const theme = {
  global: {
    background: Colors.white,
    fontFamily: 'Metropolis, sans-serif'
  },
  articlePage: {
    background: Colors.white
  },
  home: {
    wrapper: {
      padding: '0 0 0 0'
    }
  },
  aside: {
    backgroundColor: Colors.grey,
    padding: '7px'
  },
  fontface: {
    primaryFont: 'Metropolis',
    primaryFontName: 'Metropolis',
    secondaryFont: 'Open Sans',
    secondaryFontName: 'Open Sans'
  },
  logo: {
    width: 'auto',
    wrapper: {
      width: 'auto',
      height: 'auto',
      maxHeight: '100%'
    }
  },
  title: {
    wrapper: {
      alignItems: 'stretch',
      margin: {
        default: '0 0 10px 0',
        md: '0 0 10px 0'
      },
      padding: {
        default: '10px 7px 10px 15px',
        md: '10px 7px 10px 15px'
      },
      borderRadius: {
        default: '0',
        md: '0'
      },
      isBackground: true,
      background: Colors.grey
    },
    text: {
      padding: {
        default: 0,
        md: 0
      },
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 3.0,
        lg: 2.8,
        md: 2.4,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 200,
      textTransform: 'uppercase'
    },
    textWidget2: {
      padding: {
        default: 0,
        md: 0
      },
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 3.0,
        lg: 2.8,
        md: 2.4,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 200,
      textTransform: 'none'
    },
    subtext: {
      paddingDefault: '0px 0px 0px 6px',
      paddingMd: '0px 0px 6px 6px',
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: 1.2,
      lineHeight: 1.6,
      fontWeight: 200,
      textTransform: 'uppercase'
    },
    link: {
      margin: {
        default: '15px 0px 0px auto',
        md: '10px 0px 0px auto'
      },
      padding: {
        default: '10px 15px',
        md: '5px 7px'
      },
      borderRadius: {
        default: 0,
        md: 0,
        sm: 0
      },
      background: Colors.darkGrey,
      color: Colors.white,
      colorOnHover: darken(Colors.white, 10),
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      triangleShape: undefined
    },
    arrow: {
      faIcon: 'none',
      margin: {
        default: '0 0 0 0px',
        md: '0 0 0 0px'
      },
      minWidth: '0px',
      minHeight: '0px',
      borderRadius: {
        default: '0',
        md: '0'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white
    }
  },
  button: {
    padding: {
      default: '15px 25px',
      md: '5px 15px'
    },
    background: Colors.sprout,
    backgroundOnHover: darken(Colors.sprout, 50),
    color: Colors.white,
    colorOnHover: Colors.white,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1,
    fontWeight: 700
  },
  buttonIcon: {
    position: 'right-center',
    minWidth: 'auto',
    minHeight: 'auto',
    faIcon: faAngleDoubleRight,
    margin: {
      default: '0 5px 0 5px',
      md: '0 5px 0 5px'
    },
    borderRadius: {
      default: 0,
      md: 0
    },
    background: 'transparent',
    color: 'inherit'
  },
  loadingScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.astronautBlue
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  errorScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.astronautBlue
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  loadingData: {
    wrapper: {
      primaryBackground: 'transparent',
      secondaryBackground: 'transparent',
      color: Colors.astronautBlue
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  toolbar: {
    wrapper: {
      color: Colors.white
    },
    item: {
      left: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: Colors.white
      },
      right: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: Colors.white
      }
    }
  },
  toolbarSocial: {
    item: {
      color: Colors.white,
      colorOnHover: 'inherit',
      background: 'transparent',
      backgroundOnHover: Colors.brinkPink,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  toolbarSettings: {
    item: {
      color: Colors.white,
      colorOnHover: 'inherit',
      background: 'transparent',
      backgroundOnHover: Colors.brinkPink,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  header: {
    wrapper: {
      background: Colors.white,
      boxShadow: `none`,
      padding: {
        default: '15px 0',
        lg: '10px 0',
        md: '5px 0',
        sm: '2px 0'
      },
      zIndex: 'initial'
    },
    box: {
      gridGap: '25px 10px'
    },
    logoHolder: {
      alignItems: 'flex-start'
    },
    toggleButton: {
      color: Colors.astronautBlue,
      colorOnHover: Colors.white,
      backgroundOnHover: Colors.sprout,
      colorOnToggle: Colors.white,
      backgroundOnToggle: Colors.sprout
    },
    logo: {
      image: {
        width: 1395,
        height: 690,
        widthTablet: 969,
        heightTablet: 480,
        widthMobile: 767,
        heightMobile: 480,
        crop: 0
      }
    }
  },
  blackHole: {
    wrapper: {
      color: Colors.bigStone
    },
    title: {
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.2,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  banner: {
    wrapper: {
      marginTop: '10px'
    },
    box: {
      boxShadow: `0 0 36px 0 ${rgba(Colors.black, 0.16)}`
    },
    item: {
      height: {
        default: '630px',
        lg: '480px'
      }
    },
    image: {
      width: 1395,
      height: 690,
      widthTablet: 969,
      heightTablet: 480,
      widthMobile: 767,
      heightMobile: 480
    },
    contentInner: {
      gridRows: {
        default: '1fr',
        lg: '1fr'
      }
    },
    details: {
      justifyContent: 'flex-end',
      padding: {
        default: '25px 0',
        md: '15px 0'
      }
    },
    detailsInner: {
      margin: {
        default: '0 25px 0 0',
        md: '0 15px 0 0'
      },
      padding: {
        default: '25px',
        md: '15px'
      },
      borderRadius: {
        default: '25px',
        md: '15px'
      },
      background: Colors.astronautBlue
    },
    title: {
      margin: {
        default: '-55px -55px 15px 0',
        md: '-25px -25px 5px 0'
      },
      padding: {
        default: '25px',
        md: '15px'
      },
      borderRadius: {
        default: '25px',
        md: '15px'
      },
      background: Colors.white,
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    button: {
      hideBackground: true,
      faIcon: faAngleDoubleRight,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.white,
      colorOnHover: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    buttonIcon: {
      position: 'right-center',
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white
    },
    navigation: {
      top: '45px',
      bottom: 0
    },
    travel: {
      margin: {
        default: 0,
        lg: 0
      }
    }
  },
  bannerTravel: {
    holder: {
      shape: TRAVEL_SHAPE,
      shapeBackground: Colors.brinkPink
    },
    wrapper: {
      margin: {
        default: 0,
        lg: '0 -15px'
      },
      borderRadius: { default: '0 0 25px 25px', lg: 0 },
      background: Colors.white,
      boxShadow: {
        default: `0 0 3px 0 ${rgba(Colors.black, 0.16)}`,
        lg: `0 0 3px 0 ${rgba(Colors.black, 0.16)}`
      }
    },
    box: {
      padding: {
        default: '10px 0',
        lg: 0
      }
    },
    item: {
      padding: {
        default: '10px 20px',
        lg: '10px 15px',
        md: '10px 15px '
      },
      border: 'transparent'
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: '10px',
        md: '5px'
      }
    },
    title: {
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      bannerTitleSpacing: '3rem',
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      faIcon: faAngleDoubleRight
    }
  },
  bannerNavigation: {
    wrapper: {
      top: '50%',
      transform: 'translate(0, -50%)',
      height: '434px'
    },
    panel: {
      background: 'transparent'
    },
    panelInner: {
      margin: 0,
      padding: '5px 25px',
      thumb: Colors.brinkPink,
      track: rgba(Colors.black, 0.6)
    },
    navigationButton: {
      position: 'relative',
      width: '130px',
      height: '36px',
      isBackground: true,
      background: Colors.brinkPink,
      backgroundOnHover: darken(Colors.brinkPink, 50),
      color: Colors.white,
      colorOnHover: Colors.white,
      prev: {
        top: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: '10px'
      },
      next: {
        bottom: 'initial',
        left: 'initial',
        right: 'initial',
        transform: 'translate(0, 0)',
        borderRadius: '10px'
      }
    },
    box: {
      gridGap: '5px',
      padding: '0'
    },
    item: {
      minHeight: '100px',
      padding: '10px 0px 10px 20px',
      background: 'transparent',
      backgroundOnSelected: 'transparent',
      border: 0,
      borderOnSelected: 0,
      borderOnSelectedColor: 'transparent',
      borderAfterOnSelected: 0,
      borderAfterOnSelectedColor: 'transparent'
    },
    itemInner: {
      gridColumns: '100px 1fr',
      background: Colors.white
    },
    imagebox: {
      width: '100px',
      minHeight: '100px',
      height: '100px',
      margin: '-10px 0 -10px -10px',
      triangleShape: undefined
    },
    image: {
      width: 100,
      height: 100,

      borderRadius: '10px'
    },
    featured: {
      top: '0px',
      right: 'initial',
      left: 0,
      bottom: 'initial',
      transform: 'translate(-25%, -5%)',
      justifyContent: 'center',
      shape: FEATURED_SHAPE,
      isText: false,
      minWidth: '41px',
      minHeight: '34px',
      padding: '4px 5px',
      borderRadius: '100%',
      background: 'transparent',
      color: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      width: 'auto',
      height: 'auto',
      margin: '0 0 0 5px',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: 1.5
    },
    details: {
      padding: '20px',
      triangleShape: undefined
    },
    title: {
      margin: 0,
      padding: '0 40px 0 0',
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 1.6,
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      isText: false,
      color: Colors.astronautBlue,
      colorOnHover: darken(Colors.astronautBlue, 50),
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 1.3,
      lineHeight: 1.2,
      fontWeight: 700
    },
    arrow: {
      position: 'right-center',
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: '100%',
      invert: true,
      color: Colors.brinkPink,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white
    }
  },
  headline: {
    category: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.sprout,
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    title: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    subtitle: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 3.6,
        lg: 2.8,
        md: 2.2,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    imagebox: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      }
    },
    image: {
      width: 1395,
      height: 480,
      widthTablet: 922,
      heightTablet: 317,
      widthMobile: 720,
      heightMobile: 180,

      borderRadius: {
        default: '0',
        md: '0'
      }
    }
  },
  articleGallery: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    box: {
      gridTemplateColumns: 3,
      gridTemplateColumnsMD: 2,
      gridGap: 25
    },
    caption: {
      fontFamily: 'Open Sans, sans-serif'
    },
    image: {
      width: 230,
      height: 173,
      widthTablet: 307,
      heightTablet: 307,
      widthMobile: 360,
      heightMobile: 360
    }
  },
  widgets: {
    wrapper: {
      gridGap: {
        default: '10px',
        md: '10px'
      }
    }
  },
  widgetDisabledFacilities: {
    item: {
      color: Colors.black,
      colorOnHover: Colors.white,
      colorOnActive: Colors.white,
      background: 'none',
      backgroundOnHover: darken(Colors.black, 50),
      backgroundOnActive: darken(Colors.black, 50),
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetTitle: {
    title: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      padding: {
        default: '6px 2px 6px 2px',
        md: '6px 2px 6px 2px'
      },
      borderRadius: {
        default: '0px',
        md: '0px'
      },
      background: 'transparent',
      isBackground: false,
      borderColor: Colors.black,
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 200
    },
    spacer: {
      isSpacer: true,
      background: Colors.black
    }
  },
  widgetSchoolMenu: {
    backgroundColor: Colors.casper,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.ziggurat,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      backgroundColor: Colors.ziggurat,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    mealsBox: {
      padding: {
        default: '0 20px 18px'
      }
    },
    meal: {
      margin: {
        default: '0 0 24px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      border: `1px dashed ${Colors.white}`
    },
    innerTitle: {
      margin: {
        default: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    decoration: {
      color: Colors.orange,
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.8
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    footer: {
      backgroundColor: Colors.blueberry,
      padding: {
        default: '20px 64px 20px 20px'
      }
    },
    footerText: {
      margin: { default: '0' },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetLessonPlan: {
    backgroundColor: Colors.lightGreen,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.jetStream,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    selectContainer: {
      padding: {
        default: '0 24px 40px'
      },
      backgroundColor: Colors.jetStream
    },
    selectText: {
      margin: {
        default: '0 0 4px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    select: {
      padding: {
        default: '9px 17px 9px 12px'
      },
      border: `1px solid ${Colors.acapulco}`,
      borderRadius: '4px',
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '5px 0 24px 0'
      },
      backgroundColor: Colors.jetStream,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    table: {
      padding: {
        default: '0 20px 34px'
      }
    },
    content: {
      color: Colors.blueberry,
      secondColor: Colors.white,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 700,
      lineHeight: 1.2,
      row: {
        padding: {
          default: '16px 4px 16px 4px'
        },
        border: `1px dashed ${Colors.white}`
      }
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetSpacer: {
    spacer: {
      background: Colors.blizzardBlue
    }
  },
  widgetSpacerVertical: {
    background: Colors.darkRed
  },
  widgetNameDay: {
    color: Colors.lightBlack,
    fontSize: 1,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    padding: '0 0 0 0'
  },
  widgetList: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 15px 0'
      },
      padding: {
        default: '6px 2px 6px 2px',
        md: '6px 2px 6px 2px'
      },
      borderRadius: {
        default: '0px',
        md: '0px'
      },
      background: 'transparent',
      isBackground: false,
      borderColor: `2px solid ${Colors.black}`,
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 200
    },
    box: {
      gridGap: {
        default: '14px',
        md: '14px'
      }
    }
  },
  widgetItem: {
    item: {
      minHeight: {
        default: '79px',
        md: '46px'
      },
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      padding: {
        default: '0 50px 0 0',
        md: '0 40px 0 0'
      },
      gridGap: {
        default: '10px',
        md: '10px'
      },
      borderRadius: {
        default: '0px',
        md: '0px'
      },
      after: {
        padding: '14px 17px 14px 17px',
        paddingMd: '5px 5px 5px 5px',
        backgroundColor: Colors.sprout,
        backgroundColorOnHover: darken(Colors.sprout, 25),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 1.4,
        fontSizeMd: 1.2,
        fontWeight: 700,
        lineHeight: 1.2
      },
      background: 'none',
      border: 'none',
      borderColor: Colors.sprout,
      borderFirstTop: `none`,
      borderLastBottom: `3px solid`,
      borderLastBottomColor: Colors.sprout,
      boxShadow: `0`,
      gridTemplateColumns: '1fr'
    },
    imagebox: {
      display: 'none',
      margin: {
        default: '-10px 0 -10px -25px',
        md: '-10px 0 -10px -25px'
      }
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: '10px',
        md: '5px'
      }
    },
    text: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      padding: {
        default: '0 2px 10px 2px',
        md: '0 2px 10px 2px'
      },
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 200
    },
    arrow: {
      display: 'none',
      position: 'right-center',
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white
    }
  },
  widgetContactForm: {
    title: {
      margin: {
        default: '0 0 30px 0',
        md: '0 0 30px 0'
      },
      padding: {
        default: '10px 2px 10px 2px',
        md: '5px 2px 5px 2px'
      },
      borderRadius: {
        default: '0px',
        md: '0px'
      },
      background: 'transparent',
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: false,
      background: Colors.blizzardBlue
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      },
      padding: {
        default: '20px 0',
        md: '10px 0'
      }
    },
    item: {
      margin: {
        default: '0 0 21px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 6px 0',
        md: '0 0 5px 0'
      },
      color: Colors.black,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 200
    },
    textarea: {
      padding: {
        default: '10px',
        md: '5px'
      },
      border: Colors.white,
      background: Colors.white,
      placeholderColor: Colors.gray68,
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: `none`
    },
    checkboxLabel: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.black,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkbox: {
      border: Colors.black,
      background: Colors.white,
      color: Colors.brinkPink,
      boxShadow: `none`
    },
    buttonHolder: {
      border: '3px solid',
      borderColor: Colors.sprout,
      justifyContent: 'flex-end'
    },
    button: {
      padding: {
        default: '10px 20px',
        md: '5px 10px'
      },
      background: Colors.sprout,
      backgroundOnHover: darken(Colors.sprout, 25),
      color: Colors.white,
      colorOnHover: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 700,
      minHeight: 'auto',
      height: 'auto',
      borderRadius: 0
    }
  },
  widgetImage: {
    image: {}
  },
  widgetAdvertisement: {
    image: {}
  },
  widgetWeather: {
    box: {
      color: Colors.black
    },
    iconContainer: {
      margin: {
        default: '0 15px 0 0',
        md: '0 15px 0 0'
      },
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      }
    },
    temperature: {
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      margin: {
        default: '0 15px 0 0',
        md: '0 15px 0 0'
      },
      fontSize: {
        default: 1.0,
        lg: 1.0,
        md: 1.0,
        sm: 1.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetBanner: {
    image: {},
    textBox: {
      padding: 30,
      paddingMd: 15,
      top: '50%',
      left: '50%',
      right: '0',
      bottom: 'auto',
      transform: 'translateY(-50%)',
      backgroundColor: Colors.white,
      backgroundOpacity: 'CC', // CC to opacity 0.8 bo zakładam że to też sobie będą chcieli zmieniać - https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
      backgroundColorMd: Colors.mercury,
      positionMd: 'static'
    },
    title: {
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 200
    },
    box: {
      padding: 10,
      paddingMd: 5,
      margin: '0 -27px',
      marginCont: '0 -2px',
      backgroundColor: Colors.sprout
    }
  },
  widgetIcon: {
    icon: {
      borderRadius: '50%',
      backgroundColor: Colors.black,
      backgroundColorOnHover: lighten(Colors.black, 5),
      color: Colors.white,
      display: 'flex',
      height: '27px',
      margin: '0 auto',
      width: '27px'
    },
    item: {
      color: Colors.black
    },
    text: {
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetLuckyNumber: {
    title: {
      color: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 7.2,
        lg: 6.4,
        md: 5.2,
        sm: 4.8
      },
      lineHeight: 1.2,
      fontWeight: 700,
      fontStyle: 'italic'
    }
  },
  widgetPartners: {
    title: {
      margin: {
        default: '0 0 40px 0',
        md: '0 0 20px 0'
      },
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 3.0,
        lg: 3.0,
        md: 2.0,
        sm: 2.0
      },
      fontWeight: 200,
      lineHeight: 1.2
    }
  },
  widgetCalendar: {
    backgroundColor: Colors.sprout,
    container: {
      padding: {
        default: '36px 26px 9px 26px'
      }
    },
    box: {
      margin: {
        default: '0 0 22px 0'
      }
    },
    calendarTop: {
      margin: {
        default: '0 0 20px 0'
      }
    },
    arrowButton: {
      fontSize: {
        default: 2.7,
        sm: 2.0
      }
    },
    monthYear: {
      color: Colors.black,
      fontSize: {
        default: 2.2,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    dayNamesWrapper: {
      margin: {
        default: '0 0 12px 0'
      }
    },
    dayName: {
      color: Colors.black,
      fontSize: {
        default: 1.4,
        xs: 1.2
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    table: {
      rowGap: '7px',
      columnGap: '1px'
    },
    day: {
      padding: {
        default: '7px 0 7px 0',
        sm: '5px 0 5px 0'
      },
      backgroundColor: {
        default: Colors.white,
        weekend: Colors.mercury2,
        clickable: Colors.camouflageGreen,
        hover: lighten(Colors.camouflageGreen, 10),
        selected: Colors.shiraz
      },
      color: Colors.black,
      borderColor: Colors.dove,
      fontSize: {
        default: 2.3,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif',
      width: {
        default: '36px',
        sm: '25px'
      }
    },
    event: {
      color: Colors.white,
      margin: {
        default: '0 0 20px 0'
      },
      padding: {
        default: '0 50px 0 0',
        sm: '0 30px 0 0'
      },
      after: {
        padding: '14px 17px 14px 17px',
        paddingSm: '7px 9px 7px 9px',
        backgroundColor: Colors.doveGray,
        backgroundColorOnHover: darken(Colors.doveGray, 50),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Metropolis, sans-serif',
        fontSize: 1.4,
        fontSizeSm: 1.2,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    title: {
      margin: {
        default: '0 0 5px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 700,
      fontFamily: 'Metropolis, sans-serif'
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 400,
      fontFamily: 'Metropolis, sans-serif'
    },
    link: {
      padding: {
        default: '14px 12px 14px 12px',
        sm: '7px 6px 7px 6px'
      },
      backgroundColor: {
        default: Colors.doveGray,
        hover: darken(Colors.doveGray, 50)
      },
      color: Colors.white,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: 1.4,
      fontWeight: 700
    }
  },
  widgetEvents: {
    eventsContainer: {
      margin: {
        default: '0 27px 0 0',
        xlg: '0 0 20px 0'
      }
    },
    bar: {
      padding: {
        default: '7px 0 7px 7px',
        xlg: '7px 7px 7px 7px'
      },
      backgroundColor: Colors.grey
    },
    headTitle: {
      color: Colors.black,
      fontSize: {
        default: 3.0,
        lg: 2.8,
        md: 2.4,
        sm: 2.4
      },
      fontWeight: 200,
      fontFamily: 'Open Sans, sans-serif'
    },
    button: {
      padding: {
        default: '14px 12px 14px 12px',
        md: '7px 6px 7px 6px'
      },
      backgroundColor: {
        default: Colors.doveGray,
        hover: darken(Colors.doveGray, 50)
      },
      color: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.4,
        md: 1.2
      },
      fontWeight: 700
    },
    box: {
      padding: {
        default: '17px 0 0 0'
      },
      gap: {
        default: '27px',
        sm: '15px'
      }
    },
    event: {
      padding: {
        default: '17px 17px 17px 17px'
      },
      backgroundColor: Colors.grey,
      after: {
        padding: '10px 23px 10px 23px',
        backgroundColor: Colors.sprout,
        backgroundColorOnHover: darken(Colors.sprout, 25),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 1.4,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    imageBox: {
      margin: {
        default: '0 0 42px 0'
      }
    },
    dateBox: {
      padding: {
        default: '10px 7px 10px 7px'
      },
      backgroundColor: Colors.sprout
    },
    day: {
      margin: {
        default: '0 0 8px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 3.6
      },
      fontWeight: 200,
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1
    },
    month: {
      color: Colors.white,
      fontSize: {
        default: 0.8
      },
      fontWeight: 200,
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1
    },
    title: {
      margin: {
        default: '0 0 12px 0'
      },
      padding: {
        default: '0 25px 0 0'
      },
      color: Colors.black,
      fontSize: {
        default: 1.4,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Open Sans, sans-serif'
    },
    text: {
      padding: {
        default: '0 25px 0 0'
      },
      color: Colors.black,
      fontSize: {
        default: 0.9,
        sm: 1.4
      },
      fontWeight: 200,
      fontFamily: 'Open Sans, sans-serif'
    }
  },
  articlesList: {
    wrapper: {
      margin: {
        default: '55px 0 55px 0',
        lg: '55px 0 55px 0',
        md: '55px 0 25px 0',
        sm: '25px 0 25px 0'
      }
    },
    box: {
      gridGap: {
        default: '60px',
        lg: '40px',
        md: '30px',
        sm: '20px'
      }
    },
    itemHolder: {
      shape: undefined,
      shapeBackground: Colors.brinkPink
    },
    item: {
      gridColumns: {
        default: '1fr minmax(50%, 2fr)',
        lg: '1fr',
        md: '1fr 2fr',
        sm: '1fr'
      },
      gridGap: '10px',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      background: Colors.white,
      backgroundColorOnHoverLink: darken(Colors.sprout, 25),
      borderRadius: {
        default: 0,
        md: 0
      },
      border: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        lastOfType: {
          bottom: 0
        }
      },
      boxShadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    imagebox: {
      maxWidth: 'initial',
      height: 'auto',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },

      triangleShape: undefined
    },
    image: {
      width: 570,
      height: 427,
      widthTablet: 456,
      heightTablet: 342,
      widthMobile: 720,
      heightMobile: 540,

      borderRadius: {
        default: 0,
        md: 0
      }
    },
    featured: {
      top: 0,
      right: 0,
      left: 'initial',
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'center',
      shape: undefined,
      isText: false,
      minWidth: {
        default: '90px',
        lg: '80px',
        md: '70px',
        sm: '60px'
      },
      minHeight: {
        default: '50px',
        lg: '50px',
        md: '50px',
        sm: '40px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      padding: '4px 5px',
      background: rgba(Colors.black, 0.5),
      color: Colors.white,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 700,
      boxShadow: 'none'
    },
    star: {
      position: 'relative',
      top: 'initial',
      left: 'initial',
      transform: 'translate(0, 0)',
      order: 2,
      width: 'auto',
      height: 'auto',
      margin: '0 0 0 5px',
      borderRadius: 0,
      background: 'transparent',
      color: Colors.white,
      fontSize: {
        default: 2.5,
        xlg: 2,
        lg: 2,
        md: 1.5,
        sm: 1.5
      }
    },
    details: {
      height: '100%',
      minHeight: '150px',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '25px 50px 25px 25px',
        lg: '0 35px 15px 15px',
        md: '15px 35px 15px 15px',
        sm: '0 35px 15px 15px'
      },
      triangleShape: undefined
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 2.0,
        lg: 1.8,
        md: 1.6,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      margin: {
        default: 0,
        md: 0
      },
      color: Colors.astronautBlue,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      position: 'absolute',
      padding: '10px 30px 10px 25px',
      paddingMd: '5px 15px 5px 15px',
      backgroundColor: Colors.sprout,
      transform: 'rotate(-90deg)',
      transformOrigin: 'top',
      isText: false,
      color: Colors.white,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        isText: true,
        fontSize: {},
        color: Colors.sprout,
        backgroundColor: Colors.white,
        transform: 'rotate(-90deg) translateX(8px)',
        transformLg: 'rotate(-90deg) translateX(-180px)'
      }
    },
    arrow: {
      display: 'none',
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      faIcon: faAngleDoubleRight,
      backgroundOnHover: Colors.white
    }
  },
  articlesGridNew: {
    wrapper: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: 'transparent',
      padding: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      gridTemplateColumns: {
        default: '1fr 1fr 1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      columnGap: {
        default: '25px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      },
      rowGap: {
        default: '10px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      }
    },
    itemHolder: {
      shape: undefined,
      main: {
        gridArea: {
          default: '1 / 1 /4 / 3',
          lg: '2 / 1 / 3 /3',
          md: '2 / 1 / 3 /3',
          sm: '2 / 1 / 2 /2'
        },
        gridAreaReverted: {
          default: '1 / 3 / 4 / 5',
          lg: '2 / 1 / 3 /3',
          md: '2 / 1 / 3 /3',
          sm: '2 / 1 / 2 /2'
        },
        margin: {
          default: '-35px 0 0 0',
          lg: '0 0 0  0',
          md: '0 0 0 0',
          sm: '0 0 0 0'
        },
        shape: ARTICLES_LIST_SHAPE,
        shapeBackground: Colors.brinkPink
      }
    },
    item: {
      color: `${Colors.white} !important`,
      backgroundColorOnHoverLink: darken(Colors.sprout, 25),
      backgroundColorOnHoverLinkMain: darken(Colors.white, 25),
      colorOnHoverLinkMain: Colors.white,
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      boxShadow: 'none',
      border: {
        left: 'none',
        right: 'none',
        bottom: 'none',
        top: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      main: {
        gridColumns: {
          default: '1fr',
          lg: '1fr',
          md: '1fr',
          sm: '1fr'
        },
        margin: {
          default: '0 0 0 0',
          lg: 0,
          md: '0 0 0 0',
          sm: 0
        },
        padding: {
          default: '10px',
          lg: '25px 10px 10px 10px',
          md: '25px 10px 10px 10px',
          sm: '10px 10px 10px 10px'
        },
        background: Colors.sprout,
        borderRadius: {
          default: '0',
          md: '0'
        },
        boxShadow: `none`
      }
    },
    imagebox: {
      maxWidth: 'initial',
      order: '1',

      margin: {
        default: '0 0 10px 0',
        lg: '0 0 10px 0',
        md: '0 0 10px 0',
        sm: '0 0 10px 0'
      },
      main: {
        maxWidth: 'initial',
        margin: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        }
      }
    },
    image: {
      width: 224,
      height: 166,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 262,

      borderRadius: {
        default: '0',
        md: '0'
      },
      main: {
        width: 528,
        height: 452,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 730,
        heightMobile: 547,

        borderRadius: {
          default: '0',
          md: '0'
        }
      }
    },
    featured: {
      position: 'top-right-edge',
      shape: FEATURED_SHAPE,
      isText: false,
      minWidth: {
        default: '51px',
        lg: '51px',
        md: '41px',
        sm: '41px'
      },
      minHeight: {
        default: '44px',
        lg: '44px',
        md: '34px',
        sm: '34px'
      },
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      background: Colors.grey,
      color: Colors.white,
      top: {
        default: '-15px',
        lg: '-15px',
        md: '-15px',
        sm: '-15px'
      },
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      right: '-20px',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        top: {
          default: '-15px',
          lg: '-15px',
          md: '-15px',
          sm: '-15px'
        },
        bottom: {
          default: 'unset',
          lg: 'unset',
          md: 'unset',
          sm: 'unset'
        },
        position: 'top-right-edge',
        shape: FEATURED_SHAPE,
        isText: false,
        minWidth: {
          default: '92px',
          lg: '82px',
          md: '72px',
          sm: '62px'
        },
        minHeight: {
          default: '72px',
          lg: '62px',
          md: '52px',
          sm: '42px'
        },
        borderRadius: {
          default: '100%',
          md: '100%'
        },
        background: 'transparent',
        color: Colors.white,
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      margin: '0 0 0 25%',
      color: Colors.white,
      faIcon: 'none',
      position: 'relative',
      main: {
        fontSize: {
          default: 2.5,
          lg: 2.5,
          md: 2,
          sm: 2
        },
        faIcon: 'none',
        margin: '0 0 0 25%',
        color: Colors.white
      }
    },
    details: {
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        color: Colors.white,
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '15px 50px 0 15px',
          lg: '10px 50px 10px 10px',
          md: '15px',
          sm: '0 15px 15px'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      wordBreak: 'break-word',
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      main: {
        color: Colors.white,
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        }
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: 'inherit',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      position: 'absolute',
      padding: '10px 30px 10px 25px',
      backgroundColor: Colors.sprout,
      transform: 'rotate(-90deg) translateX(86px)',
      isText: false,
      color: Colors.white,
      colorOnHover: lighten(Colors.black, 50),
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        isText: true,
        fontSize: {},
        color: Colors.sprout,
        backgroundColor: Colors.white,
        transform: 'rotate(-90deg) translateX(8px)',
        transformLg: 'rotate(-90deg) translateX(-180px)'
      }
    },
    arrow: {
      minWidth: '0',
      minHeight: '0',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white,
      faIcon: 'none',
      main: {
        fontSize: {}
      }
    }
  },
  galleriesGrid: {
    box: {
      columnGap: '25px',
      rowGap: '17px'
    },
    imagebox: {
      margin: {
        default: '0 0 10px 0'
      },
      after: {
        padding: '8px 23px 8px 23px',
        backgroundColor: Colors.sprout,
        backgroundColorOnHover: darken(Colors.sprout, 25),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 1.4,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    text: {
      color: Colors.black,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      },
      fontWeight: 700,
      lineHeight: 1.2
    }
  },
  articlesGrid: {
    wrapper: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: 'transparent',
      padding: {
        default: '25px 0 0 0',
        md: '25px 0 0 0'
      },
      gridTemplateColumns: {
        default: '1fr 1fr 1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr 1fr'
      },
      gridGap: {
        default: '20px',
        lg: '20px',
        md: '20px',
        sm: '20px'
      }
    },
    itemHolder: {
      shape: undefined,
      main: {
        gridArea: {
          default: '1 / 1 / 2 / 5',
          lg: '1 / 1 / 2 / 3',
          md: '1 / 1 / 2 / 3',
          sm: '1 / 1 / 2 / 3'
        },
        margin: {
          default: '0 0 55px 0',
          lg: '0 0 55px 0',
          md: '0 0 30px 0',
          sm: '0 0 30px 0'
        },
        shape: ARTICLES_LIST_SHAPE,
        shapeBackground: Colors.brinkPink
      }
    },
    item: {
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      boxShadow: 'none',
      border: {
        left: 'none',
        right: 'none',
        bottom: 'none',
        top: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      main: {
        gridColumns: {
          default: '1fr 1fr',
          lg: '1fr',
          md: '1fr 1fr',
          sm: '1fr'
        },
        margin: {
          default: '0 0 0 25px',
          lg: 0,
          md: '0 0 0 25px',
          sm: 0
        },
        padding: {
          default: '5px 0',
          lg: 0,
          md: 0,
          sm: 0
        },
        background: Colors.white,
        borderRadius: {
          default: '55px',
          md: '25px'
        },
        boxShadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
      }
    },
    imagebox: {
      maxWidth: 'initial',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        maxWidth: 'initial',
        margin: {
          default: '-55px 0 -55px -25px',
          lg: '-5px -5px 0 -5px',
          md: '-25px 0 -25px -25px',
          sm: '-5px -5px 0 -5px'
        }
      }
    },
    image: {
      width: 248,
      height: 186,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 262,

      borderRadius: {
        default: '25px',
        md: '15px'
      },
      main: {
        width: 528,
        height: 396,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 730,
        heightMobile: 547,

        borderRadius: {
          default: '55px',
          md: '25px'
        }
      }
    },
    featured: {
      position: 'top-right-edge',
      shape: FEATURED_SHAPE,
      isText: false,
      minWidth: {
        default: '51px',
        lg: '51px',
        md: '41px',
        sm: '41px'
      },
      minHeight: {
        default: '44px',
        lg: '44px',
        md: '34px',
        sm: '34px'
      },
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      background: Colors.brinkPink,
      color: Colors.white,
      top: {
        default: '-15px',
        lg: '-15px',
        md: '-15px',
        sm: '-15px'
      },
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      right: '-20px',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        top: {
          default: '-15px',
          lg: '-15px',
          md: '-15px',
          sm: '-15px'
        },
        bottom: {
          default: 'unset',
          lg: 'unset',
          md: 'unset',
          sm: 'unset'
        },
        position: 'top-right-edge',
        shape: FEATURED_SHAPE,
        isText: false,
        minWidth: {
          default: '92px',
          lg: '82px',
          md: '72px',
          sm: '62px'
        },
        minHeight: {
          default: '72px',
          lg: '62px',
          md: '52px',
          sm: '42px'
        },
        borderRadius: {
          default: '100%',
          md: '100%'
        },
        background: 'transparent',
        color: Colors.white,
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      margin: '0 0 0 25%',
      color: Colors.white,
      faIcon: faAngleDoubleRight,
      position: 'relative',
      main: {
        fontSize: {
          default: 2.5,
          lg: 2.5,
          md: 2,
          sm: 2
        },
        faIcon: faAngleDoubleRight,
        margin: '0 0 0 25%',
        color: Colors.white
      }
    },
    details: {
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '25px',
          lg: '0 15px 15px',
          md: '15px',
          sm: '0 15px 15px'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      wordBreak: 'break-word',
      color: Colors.astronautBlue,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      main: {
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 2.8
        }
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.astronautBlue,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      isText: false,
      color: Colors.astronautBlue,
      colorOnHover: darken(Colors.astronautBlue, 50),
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      main: {
        isText: true,
        fontSize: {}
      }
    },
    arrow: {
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white,
      faIcon: faAngleDoubleRight,
      main: {
        fontSize: {}
      }
    }
  },

  pagination: {
    current: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      color: Colors.sprout,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 900
    },
    link: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: Colors.sprout,
      color: Colors.black,
      colorOnHover: Colors.white,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 2.2,
        lg: 2.2,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    linkArrow: {
      borderRadius: { default: 0, lg: 0 },
      minWidth: {
        default: '36px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '36px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      faIcon: faAngleDoubleRight,
      color: Colors.sprout,
      colorOnHover: darken(Colors.sprout, 25),
      fontSize: {
        default: 2.2,
        lg: 2.2,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: 'inherit',
      colorOnHover: 'inherit',
      faIconRight: faAngleDoubleRight,
      faIconLeft: faAngleDoubleLeft
    }
  },
  navMenu: {
    item: {
      padding: {
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    title: {
      color: Colors.black,
      colorOnHover: lighten(Colors.black, 50),
      fontSize: {
        default: 1.4,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  menuList: {
    wrapper: {
      position: 'bottom-center-edge',
      shape: 'none',
      shapeBackground: 'none',
      padding: { default: 0 },
      borderRadius: { default: 0, lg: 0 },
      background: { default: Colors.white, lg: 'transparent' },
      marginBottom: '-15px',
      textTransform: 'uppercase'
    },
    content: {
      position: 'bottom-center-edge',
      margin: { default: '0' },
      alignItems: 'baseline',
      minHeight: '50px',
      justifyContent: 'center'
    },
    item: {
      flexDirection: 'row',
      afterContent: '\\2022',
      afterColor: Colors.black,
      afterFontSize: 2.0,
      alignItems: 'baseline'
    },
    link: {
      padding: {
        default: '5px 5px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      border: Colors.mercury,
      color: Colors.black,
      colorOnHover: Colors.sprout,
      colorOnActive: Colors.sprout,
      background: 'transparent',
      borderBottomOnActive: '8px solid',
      borderBottomOnActiveColor: Colors.sprout,
      borderBottomOnNormal: `8px solid transparent`,
      beforeContent: '\\2022',
      beforePaddingRight: '1rem',
      beforeColor: Colors.black,
      backgroundOnHover: 'transparent',
      backgroundOnHoverMd: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.7,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    subLink: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.mercury,
      color: Colors.black,
      colorOnHover: Colors.sprout,
      colorOnActive: Colors.sprout,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnHoverMd: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    option: {
      padding: {
        default: '5px 5px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      borderRadius: { default: 0, lg: 0 },
      border: Colors.mercury,
      color: Colors.black,
      colorOnHover: Colors.sprout,
      colorOnActive: Colors.sprout,
      borderBottomOnActive: `8px solid ${Colors.sprout}`,
      borderBottomOnNormal: `8px solid transparent`,
      background: 'transparent',
      backgroundOnHover: Colors.white,
      backgroundOnHoverMd: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnHoverMd: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.7,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    subOption: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.mercury,
      color: Colors.black,
      colorOnHover: Colors.sprout,
      colorOnActive: Colors.sprout,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    itemContent: {
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    arrow: {
      color: Colors.sprout,
      colorOnHover: darken(Colors.sprout, 50),
      faIcon: faAngleDoubleRight
    }
  },
  megamenu: {
    wrapper: {
      borderRadius: '4px',
      padding: {
        default: 0,
        lg: '20px 0 0',
        md: '10px 0'
      },
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    main: {
      padding: {
        default: '20px 20px 0',
        lg: 0,
        md: 0
      }
    },
    aside: {
      boxShadow: {
        default: `inset 12px 0 12px -6px ${rgba(Colors.black, 0.16)}`,
        lg: 'none'
      },
      maxWidth: '398px',
      padding: {
        default: '20px 40px 0',
        lg: 0,
        md: 0
      },
      background: {
        default: rgba(Colors.mercury, 0.85),
        lg: 'transparent'
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    item: {
      padding: {
        default: '20px 0',
        md: '10px 0'
      },
      border: Colors.mercury
    },
    image: {
      width: 50,
      height: 38,

      borderRadius: {
        default: '10px',
        md: '5px'
      }
    },
    text: {
      color: Colors.black,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.sprout,
      colorOnHover: darken(Colors.sprout, 50)
    }
  },
  megamenuList: {
    wrapper: {
      thumb: Colors.sprout,
      track: rgba(Colors.black, 0.6)
    },
    item: {
      padding: {
        default: '10px 0',
        lg: '10px 0',
        md: '5px 0'
      }
    },
    text: {
      color: Colors.black,
      colorOnHover: Colors.sprout,
      colorOnActive: Colors.sprout,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.sprout,
      colorOnHover: darken(Colors.sprout, 50)
    }
  },
  cookies: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 -3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    text: {
      margin: {
        default: '15px 0',
        md: '5px 0'
      },
      color: Colors.astronautBlue,
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  CMS: {
    color: Colors.black,
    backgroundColor: 'transparent',
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1.2,
    fontWeight: 400,
    cms: {
      h1: {
        fontFamily: 'Metropolis, sans-serif',
        fontSize: {
          default: 3.2,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h2: {
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h3: {
        fontSize: {
          default: 2.2,
          lg: 2.0,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h4: {
        fontSize: {
          default: 2.0,
          lg: 1.8,
          md: 1.8,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h5: {
        fontSize: {
          default: 1.8,
          lg: 1.6,
          md: 1.6,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h6: {
        fontSize: {
          default: 1.6,
          lg: 1.6,
          md: 1.4,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      a: {
        color: Colors.sprout,
        colorOnHover: Colors.sprout
      },
      mark: {
        background: Colors.candlelight
      },
      ul: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      ol: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      table: {
        border: Colors.black
      },
      tr: {
        background: Colors.white,
        backgroundAlt: Colors.mercury
      },
      th: {
        border: Colors.black
      },
      td: {
        border: Colors.black
      },
      blockquote: {
        quotes: 'both',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400,
        quote: {
          margin: {
            default: '-7.0rem 20px 0 20px',
            md: '-5.0rem 15px 0 15px',
            sm: '-4.0rem 0 0 0'
          },
          color: Colors.sprout,
          fontFamily: 'Open Sans, sans-serif',
          fontSize: {
            default: 18.0,
            lg: 18.0,
            md: 14.0,
            sm: 10.0
          },
          lineHeight: 1,
          fontWeight: 400
        }
      }
    }
  },
  modal: {
    holder: {
      background: rgba(Colors.black, 0.6),
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    panel: {
      background: Colors.white,
      shadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    close: {
      color: Colors.astronautBlue,
      colorOnHover: Colors.tango
    }
  },
  popup: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    }
  },
  footerMenu: {
    wrapper: {
      background: Colors.brinkPink,
      padding: {
        default: '2px 0',
        md: '2px 0'
      }
    },
    item: {
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      colorOnActive: darken(Colors.white, 50),
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent'
    },
    text: {
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  footerDetails: {
    wrapper: {
      background: Colors.grey,
      padding: {
        default: '25px 0 30px',
        md: '15px 0 15px'
      }
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    column: {
      border: `none`,
      padding: '0',
      textAlignLg: 'left'
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.black,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  },
  footerCopyright: {
    wrapper: {
      background: Colors.grey,
      padding: {
        default: '12px 0',
        md: '6px 0'
      }
    },
    text: {
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1,
      fontWeight: 400,
      textAlign: {
        default: 'left',
        sm: 'center'
      },
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  }
};

export default theme;
