module.exports = `
  type GalleryCategory {
    gallery_category_id: Int
    portal_id: Int
    name: String
    meta_title: String
    meta_description: String 
    url: String
    content_short: String
    content: String
    image_main_url: String
    active: Boolean
    done: Boolean
    no:Int
  }

  
  type GalleryList {
    slug: String
    pages: Int
    articles: [Article]
  }

  type Gallery {
    gallery_id: Int
    name: String
    meta_title: String
    meta_description: String 
    url: String
    date: String
    active: Boolean
    category: String
    category_url: String
    gallery_category_id: Int
    content_short: String
    content: String 
    image_main_url: String
    images: [Gallery_image]
    done: Boolean
    no: Int
  }

  type Gallery_image {
    gallery_image_id: Int
    gallery_id: Int
    image_url: String
    name: String
    description: String,
    done: Boolean 
  }
`;
