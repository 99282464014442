module.exports = `
  type WidgetArea {
    widget_area_id: Int
    widgets: [Widget]
  }

  type Widget {
    widget_id: Int 
    data: WidgetData
  }
`;
