import styled, { css } from 'styled-components';
import { Media } from 'assets/Mixins.style';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: 0.2s all;
  background: ${props => props.theme.primaryBackground};
  background-image: ${props =>
    `linear-gradient(to bottom, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  color: ${props => props.theme.color};
  text-align: center;
  cursor: default;

  ${props =>
    props.fullmode &&
    css`
      flex-grow: 1;
    `}
`;

export const Content = styled.div`
  display: inline-block;
`;

export const Icon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 25px;
`;

export const Text = styled.div`
  display: inline-block;
  transition: 0.2s all;
  font-size: ${props => `${props.theme.fontSize.default}rem`};
  line-height: ${props => props.theme.lineHeight};
  font-weight: ${props => props.theme.fontWeight};

  ${Media.lg`
    font-size: ${props => `${props.theme.fontSize.lg}rem`};
  `}

  ${Media.md`
    font-size: ${props => `${props.theme.fontSize.md}rem`};
  `}

  ${Media.sm`
    font-size: ${props => `${props.theme.fontSize.sm}rem`};
  `}
`;
