/* eslint-disable */
import React from 'react';
import { makeExecutableSchema, addMockFunctionsToSchema } from 'graphql-tools';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SchemaLink } from 'apollo-link-schema';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

import mocks from './mocks/public';

const typeDefs = require('./schemaTypeDefs/public');
const schema = makeExecutableSchema({
  typeDefs: typeDefs
});

const apolloCache = new InMemoryCache(window.__APOLLO_STATE__);
export const customResolvers = mocks;

let createApolloMockingProvider = ({ schema, mocks }) => {
  return props => {
    addMockFunctionsToSchema({ schema, mocks });

    const client = new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.map(
              ({ message, locations, path, extensions = {} }) => {
                if (extensions.code === 401) {
                  window.location.replace('/');
                }
              }
            );
        }),
        new SchemaLink({ schema, context: {} })
      ]),
      cache: apolloCache
    });
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  };
};

export const ApolloMockingProvider = createApolloMockingProvider({
  mocks: mocks,
  schema: schema
});
